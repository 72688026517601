import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { BodyCSS } from '../../../components/ReusableComponents/Card-Body/BodyCSS';
import { BodyText, BodyTitle } from '../../../components/ReusableComponents/Text/Text';
import FormItemInput from 'components/ReusableComponents/Form/FormItemInput';
import info from 'assets/images/generic/info.svg';
import IncrementDecrementInput from '../../priceAdjustment/Optimization/components/subComponents/OptimizeDrawer/IncrementDecrementInput';
import { PrimaryButton } from '../../../components/ReusableComponents/Button/Button';
import { markets } from '../../../services/markets';
import { Select, Tooltip, message, Radio, Space, Checkbox, InputNumber } from 'antd';
import { useDispatch } from 'react-redux';
import { getListings } from '../../../appRedux/actions';
import { darkScrollbar, lightScrollbar } from 'components/ReusableComponents/Scrollbar/Scrollbar';
// import { media } from '../../../styles/stylesheet';
import CurrencyFlag from '../../../components/ReusableComponents/CurrencyFlag/CurrencyFlag';
import { user } from '../../../services/user';
import getCurrencySymbol from '../../../components/ReusableComponents/CurrencyFlag/CurrencySymbol';

const monthIndex = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

const ListingDrawerDiv = styled.div`
  ${BodyCSS}
  width: ${({drawerWidth}) => drawerWidth};
  min-height: 100vh;
  height: 100vh;
  position: fixed;
  z-index: 50;
  top: 0px;
  right: 0px;
  transition: all 0.25s linear;


`;

const ListingDrawerBody = styled.div` 
  width: 100%;
  // min-height: 1080px;
  height: 100vh;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  display: flex;
`;

const CloseButton = styled.button`
  width: fit-content: 
  height: fit-content;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
`;

const TitleDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 16px;
`;

const Title = styled(BodyTitle)`
  white-space: nowrap;
`;

const DrawerBody = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
  width: 100%;
  border-top: 1px solid #D9D9D9;
  padding: 16px;
  box-sizing: border-box;
  position: relative;

  overflow: scroll;
  ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)};

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NonSeasonalContainer = styled.div`
  width: 100%;
  margin-top: 16px;
`;

const SeasonalContainer = styled.div`
  width: 100%;
  margin-top: 16px;
`;

const SeasonalMonthDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  width: 100%;
`;

const SeasonalMonthText = styled.div`
  min-width: 150px;
`;

const SeasonalMonthInput = styled(InputNumber)`
  flex-grow: 1;
  width: 100%;
`;

const NoWrapText = styled(BodyText)`
  max-height: 42px;
  // overflow: hidden;
  align-self: flex-start;
`;

const FootText = styled(NoWrapText)`
  color: #697273;
  font-style: Italic;
`;

const SelectText = styled(BodyText)`
  align-self: flex-start;
  margin-bottom: 16px;
`;


const FormDiv = styled.div`
  width: 100%;
  height: ${({formHeight}) => formHeight};
  // margin: 16px 0 0 0;
`;

const ListingNameDiv = styled.div`
  width: 100%;
  display: flex;
  height: 48px;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 16px;
`;


const BenchmarkDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PropertyDiv = styled.div`
  width: 100%;
  display: flex;
  min-height: 88px;
  flex-direction: column;

  padding: 16px;
  border: 1px solid #F0F0F0;
`;

const SeasonalRadioDiv = styled.div`
  width: 100%;
  display: flex;
`;

const SeasonalRadioGroupContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
`;

const PropertyDivInput = styled.div`
  width: 100%;
  display: flex;
`;

const NonSeasonalInput = styled(InputNumber)`
  width: 100%;
`;

const ListingDiv = styled(PropertyDiv)`
  width: 100%;
`;

const PriceDiv = styled(PropertyDiv)`
  width: 100%;
`;

const CheckboxDiv = styled.div`
  width: 100%;
  margin-top: 5px;
`;

const ListingImg = styled.img`
  min-width: 58px;
  min-height: 40px;
  width: 58px;
  height: 40px;
  image-rendering: -webkit-optimize-contrast;
`; 

const PropertyBenchmarkDiv = styled.div`
  width: 100%;
  display: flex;
  background: #E8E8E8;
  margin: 16px 0 0 0;
  padding: 16px;
  gap: 180px;
`;

const PropertyTitle = styled(BodyTitle)`
  font-size: 18px;
`;


const LabelDiv = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  margin: 0 0 8px 0;
`;

const ApplyButtonContainer = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;

  justify-content: flex-end;
`;

const ApplyButton = styled(PrimaryButton)`
  width: 120px;
  height: 44px;
`;

const MinMaxPriceContainer = styled.div`
  display: flex;

  & > div:not(:last-child) {
    margin-right: 16px;
  }
`;

const TooltipDiv = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 100;
`;


const ItalicTooltipText = styled(BodyText)`
  font-style: italic;
`;

const BoxText = styled.span`
  border: 2px solid red;
  font-style: initial;
  padding: 4px;
  text-align: center;
`;

const CurrencySelect = styled(Select)`
  width: 100%;
  height: 40px;
`;

const MinMaxDiv = styled.div`
  flex: 1;
`;

// const CurrencyEdit = styled(CurrencySelect)`
//   margin: 0px 0 0 0;
// `;

const SelectLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;


const ListingDrawer = ({listingDrawer, setListingDrawer, updating}) => {

  const [selectCurrency, setSelectCurrency] = useState('USD');
  const [currencyList, setCurrencyList] = useState([]);
  const [onProgress, setOnProgress] = useState(false);
  const [mode, setMode] = useState('non-seasonal');
  const [isAirBnbIdOptional, setIsAirBnbIdOptional] = useState(false);
  const [monthlyPrices, setMonthlyPrices] = useState(
    Object.keys(monthIndex).reduce((acc, month) => {
      acc[month] = ''; 
      return acc;
    }, {})
  );

  const [formItems, setFormItems] = useState({
    benchmarkPrice: {value: '', error: false},
    airBnbId: {value: '', error: false},
    vrboId: {value: '', error: false},
    min: '',
    max: '',
  });

  useEffect(() => {

    const fetchCurrencies = async () => {
      const currenciesData = await user.getCurrencies();
      setCurrencyList(currenciesData);
    };

    fetchCurrencies();

  }, []);
  
  const dispatch = useDispatch();

  const currencySymbol = localStorage.getItem("currency");

  useEffect(() => {
    if (listingDrawer.isVisible) {
      setFormItems((prev) => ({
        ...prev,
        benchmarkPrice: { value: listingDrawer?.benchmarkPrice || '' },
        airBnbId: { value: listingDrawer?.airBnbId || '' },
        vrboId: { value: listingDrawer?.vrboId || '' },
        min: listingDrawer?.minPrice || '',
        max: listingDrawer?.maxPrice || '',
      }));

      if (listingDrawer?.isSeasonal) {
        const seasonalPrices = Object.entries(monthIndex).reduce((acc, [monthKey]) => {
          acc[monthKey] = listingDrawer?.benchmarkPriceSeasonal?.[monthKey] || 0;
          return acc;
        }, {});
        setMonthlyPrices(seasonalPrices);
        setMode('seasonal');
      } else {
        setMonthlyPrices(
          Object.keys(monthIndex).reduce((acc, month) => {
            acc[month] = 0;
            return acc;
          }, {})
        );
        setMode('non-seasonal');
      }

      setIsAirBnbIdOptional(listingDrawer?.isAirBnbIdOptional || false);
      setSelectCurrency(listingDrawer?.currency)
    }
  }, [
    listingDrawer.isVisible,
    listingDrawer.benchmarkPrice,
    listingDrawer.airBnbId,
    listingDrawer.vrboId,
    listingDrawer.minPrice,
    listingDrawer.maxPrice,
    listingDrawer.isSeasonal,
    listingDrawer.benchmarkPriceSeasonal,
    listingDrawer?.isAirBnbIdOptional,
    listingDrawer?.currency
  ]);  

  const onModeChange = (e) => {
    setMode(e.target.value);
  };

  const onMonthPriceChange = useCallback((month, value) => {
    setMonthlyPrices((prevPrices) => ({
      ...prevPrices,
      [month]: value,
    }));
  }, []);

  const handleCheckboxChange = (e) => { 
    setIsAirBnbIdOptional(e.target.checked)
  };

  const onCloseDrawer = () => {
    setFormItems({
      benchmarkPrice: {value: '', error: false},
      airBnbId: {value: '', error: false},
      vrboId: {value: '', error: false},
      min: '',
      max: '',
    });
    setListingDrawer((prev) => ({...prev, isVisible: false}));
  };

  const onFormChange = useCallback((formKey, {value, error}) => {
    console.log(value)
    const itemValue = formKey === 'benchmarkPrice' ? Number(value) : value;
    setFormItems((prev) => ({...prev, [formKey]: {value: itemValue, error}}));
  } ,[]);

  const onMaxInputChange = (value) => {
    setFormItems((prev) => ({...prev, max: value}))
  };

  const onMinInputChange = (value) => {
    setFormItems((prev) => ({...prev, min: value}))
  };
  
  const disableApply = listingDrawer.isEdit
  ? (!formItems.benchmarkPrice.value > 0 || onProgress)
  : (!formItems.benchmarkPrice.value > 0 || !formItems.min > 0 || !formItems.max > 0 || onProgress);

  const onApply = async () => {
    try {
      setOnProgress(true);
      const { benchmarkPrice, airBnbId, vrboId } = formItems;
  
      const params = {
        benchmarkPrice: Number(benchmarkPrice.value),
        isSeasonal: mode === 'seasonal',
        currency: selectCurrency,
        isAirBnbIdOptional,
      };
  
      if (!listingDrawer.isEdit) {
        params.minPrice = formItems.min;
        params.maxPrice = formItems.max;
      }
  
      if (airBnbId.value.length) {
        params.airBnbId = airBnbId.value;
      }
  
      if (mode === 'seasonal') {
        const benchmarkPriceSeasonal = Object.entries(monthIndex).reduce((acc, [monthKey]) => {
          acc[monthKey] = monthlyPrices[monthKey] || 0;
          return acc;
        }, {});
        params.benchmarkPriceSeasonal = benchmarkPriceSeasonal;
      }
  
      if (vrboId.value.length) {
        params.vrboId = vrboId.value;
      }
        
      const dynamicSetResult = listingDrawer.isEdit
        ? await markets.updateListingPricing({ id: listingDrawer.id, params })
        : await markets.toggleListingPricing({ id: listingDrawer.id, params });
  
      if (dynamicSetResult.status) {
        message.success("Silent Dynamic Pricing Updated Successfully");
        dispatch(getListings());
        onCloseDrawer();
      } else {
        message.error("Error on Silent Dynamic Pricing update.");
      }

      setOnProgress(false);
  
    } catch (e) {
      message.error("Error on Silent Dynamic Pricing update.");
      setOnProgress(false);
    }
  };  

  const handleSelectChange = (value) => {
    setSelectCurrency(value);
  }
  

  return (
    <ListingDrawerDiv drawerWidth={listingDrawer?.isVisible ? '572px' : '0px'} >
      <ListingDrawerBody>
        <TitleDiv>
          <Title>
            Silent Dynamic Pricing Setup 
          </Title>
          <CloseButton onClick={onCloseDrawer}>
            <BodyText>
              X
            </BodyText>
          </CloseButton>
        </TitleDiv>
        <DrawerBody>
          <NoWrapText>
            Quibble uses the benchmark price to find the appropriate competitors for this listing.  We will segment the nearby competitors from this range.
          </NoWrapText>
          <SelectText>
            Select the benchmark price you want to apply.
          </SelectText>
          <PropertyBenchmarkDiv>
            <PropertyTitle>
              Property
            </PropertyTitle>
          </PropertyBenchmarkDiv>
          <FormDiv >
            <PropertyDiv>
              <ListingNameDiv>
                <ListingImg src={listingDrawer?.thumbnailurl} alt="thumbnail url" />
                <BodyText margin="0px 0 0 8px">
                  {listingDrawer?.name}
                </BodyText>
              </ListingNameDiv>
              <SeasonalRadioDiv>
                <PropertyTitle>
                  Benchmark Price ({selectCurrency})
                </PropertyTitle>
                <SeasonalRadioGroupContainer>
                  <Radio.Group onChange={onModeChange} value={mode}>
                  <Space direction="horizontal">
                    <Radio value={'non-seasonal'}>Non-Seasonal</Radio>
                    <Radio value={'seasonal'}>Seasonal</Radio>
                  </Space>
                  </Radio.Group>
                </SeasonalRadioGroupContainer>
              </SeasonalRadioDiv>
              {
                mode === 'non-seasonal' ? 
                  <NonSeasonalContainer>
                    <BenchmarkDiv>
                      <PropertyDivInput>
                        <NonSeasonalInput
                          onChange={(value) =>
                            onFormChange('benchmarkPrice', { value: parseFloat(value), error: false })
                          }
                          value={formItems.benchmarkPrice.value}
                          formatter={(value) => `${getCurrencySymbol(selectCurrency)} ${parseFloat(value || 0)}`}
                          parser={(value) => parseFloat(value.replace(getCurrencySymbol(selectCurrency), ''))}
                          min={0}
                        />
                      </PropertyDivInput>
                    </BenchmarkDiv>
                  </NonSeasonalContainer>
                :
                  <SeasonalContainer>
                    {Object.entries(monthIndex).map(([monthKey, monthName]) => (
                      <SeasonalMonthDiv key={monthKey}>
                        <SeasonalMonthText>{monthName}</SeasonalMonthText>
                        <SeasonalMonthInput
                          value={parseFloat(monthlyPrices[monthKey] || 0)}
                          onChange={(value) => onMonthPriceChange(monthKey, parseFloat(value))}
                          formatter={(value) => `${getCurrencySymbol(selectCurrency)} ${parseFloat(value || 0)}`}
                          parser={(value) => value.replace(getCurrencySymbol(selectCurrency), '')}
                          min={0}
                        />
                      </SeasonalMonthDiv>
                    ))}
                  </SeasonalContainer>
              }
            </PropertyDiv>
            <ListingDiv>
              <NoWrapText>
                Enter the listing ID for the rental platform where you want to set the benchmark price.
              </NoWrapText>
              <FormItemInput 
                type="number"
                formKey="airBnbId" 
                onChangeFunc={onFormChange} 
                value={formItems.airBnbId.value} 
                label={<LabelDiv>Airbnb ID &nbsp; 
                  <Tooltip 
                    title={
                      <TooltipDiv > 
                        <BodyText> How to find your ID? </BodyText> 
                        <ItalicTooltipText> Ex. https://www.airbnb.com/rooms/<BoxText>123456789012345678</BoxText> </ItalicTooltipText> 
                      </TooltipDiv>
                    }>
                    <img src={info} alt='info' /> 
                  </Tooltip>
                </LabelDiv>} 
                inputProps={{
                  min: "1",
                  step: "1",
                  pattern: "\\d*",
                }}
              />
              <CheckboxDiv>
                <Checkbox checked={isAirBnbIdOptional} onChange={handleCheckboxChange}>I don’t have Airbnb ID</Checkbox>
              </CheckboxDiv>
              <FormItemInput 
                formKey="vrboId" 
                onChangeFunc={onFormChange} 
                value={formItems.vrboId.value} 
                label={<LabelDiv>Vrbo ID (Optional) &nbsp; <Tooltip 
                title={
                  <TooltipDiv > 
                    <BodyText> How to find your ID? </BodyText> 
                    <ItalicTooltipText> Ex. https://www.vrbo.com/<BoxText>3012695</BoxText> </ItalicTooltipText> 
                  </TooltipDiv>}><img src={info} alt='info' /> </Tooltip></LabelDiv>
                } 
              />
            </ListingDiv>
            <PriceDiv>
              <SelectText>
                Set Min/Max Price
              </SelectText>
              <MinMaxPriceContainer>
                <MinMaxDiv style={{ maxWidth: listingDrawer.isEdit ? '33%' : '100%'}}>
                  <BodyText margin="8px 0 10px 0">
                    Currency
                  </BodyText>
                  <CurrencySelect value={selectCurrency} onChange={handleSelectChange} options={
                    currencyList.map((data) => (
                      {
                        label:  <SelectLabel key={data} > <CurrencyFlag currency={data} /> <BodyText> {data} </BodyText> </SelectLabel>,
                        value: data,
                      }
                    ))
                    } />
                </MinMaxDiv>
                {
                  !listingDrawer.isEdit && (
                    <>
                      <MinMaxDiv>
                        <BodyText margin="8px 0 10px 0">
                          Minimum ({currencySymbol})
                        </BodyText>
                        <IncrementDecrementInput inputValue={formItems.min || 0} onInputChange={onMinInputChange}/>
                      </MinMaxDiv>
                      <MinMaxDiv>
                        <BodyText margin="8px 0 10px 0">
                          Maximum ({currencySymbol})
                        </BodyText>
                        <IncrementDecrementInput inputValue={formItems.max || 0} onInputChange={onMaxInputChange}/>
                      </MinMaxDiv>
                    </>
                  )
                }
              </MinMaxPriceContainer>
            </PriceDiv>
            <FootText>
              After this initial setup, you can access the property price adjustment screen to set  your Min/Max Prices on a monthly basis.
            </FootText>
          </FormDiv>
        </DrawerBody>
        <ApplyButtonContainer>
          <ApplyButton disabled={disableApply || updating } onClick={onApply}>{listingDrawer.isEdit ? 'Update' : 'Apply'}</ApplyButton>
        </ApplyButtonContainer>
      </ListingDrawerBody>
    </ListingDrawerDiv>
  )
}

export default ListingDrawer;