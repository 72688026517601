/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/** Default Import */
/* eslint-disable react-hooks/exhaustive-deps */
import Auxiliary from "util/Auxiliary";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Row, Card, Table, DatePicker, TreeSelect, Button, Tag } from "antd";
import styled from "styled-components";
import { DownOutlined, FilterOutlined, UpOutlined } from '@ant-design/icons';
import { Input, Slider, Checkbox } from 'antd'
/** Custom Import  */
import {
  getBookingFeed,
} from "../../../appRedux/actions";
import { currencyFormatter } from '../../../util/helper';
import CircularProgress from "components/CircularProgress";
import _ from 'lodash';
import moment from 'moment';
import RenderActiveLogo from "../../../components/ReservationsPieChart/components/RenderActiveLogo";
import { now } from "../../../constants/GlobalConstant";
import searchIcon from 'assets/images/generic/search.svg';


/** Set date format to search by year and month only */
//const { MonthPicker } = DatePicker;

const Container = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;

const StyledRow = styled.div`
  margin: 15px 0px 10px 0px;
  display: flex;
  justify-content:space-between;
  align-items: center;
  gap: 10px;
`;

const LeftRow = styled.div`
  margin: 15px 0px 10px 0px;
  display: flex;
  gap: 10px;
`;

const StyledExtendedRow = styled(Row)`
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height:100%;
  width: 100%
`;

const BookingFeedDatePicker = styled(DatePicker)`
  width: 100%;
  padding: 15px;
`;



const CardContainer= styled(Row)`
  display: flex;
  width: 100%;
  justify: space-between;
  gap: 10px;
  align-items: flex-start;
`;

const BookingFeedCard = styled(Card)`
  font-weight: 300;
  width: ${({showFilters}) => showFilters ? '80%' :'100%'};
  .ant-card-head-title {
    font-weight: 300;
  }
`;

const FilterCard = styled(Card)`
  width: 18%;
  display: ${({showFilters}) => showFilters ? 'block' : 'none'};
  font-weight: 400;
`;

const Filter = styled.div`
  padding-bottom: 20px;
`;

const FilterTitle = styled.p`
  font-weight: 400;
`;

const FilterButton = styled.button`
  color: ${({ showFilters }) => showFilters ? 'white' : '#1bb3cd'};
  background-color: ${({ showFilters}) => showFilters  ? '#1bb3cd' : 'white' };
  border: 1px solid #1bb3cd;
  border-radius: 4px;
  cursor:pointer;
  cursor:${({disabled}) => disabled? 'not-allowed': 'pointer'};
  height:50px;
  width:100%;
`;

const StyledFilterCol = styled(Col)`
  width: 100px;
`;

const StyledCol = styled(Col)`
  width: 300px;

  .ant-select-selector {
    padding: 11px; 
  }

  .ant-select-selection-placeholder {
    color: ${({ theme }) => theme.quibblePlaceholderGrey};
  }

  .ant-select-selection-item {
    color: #000; 
  }
`;

const PLabel = styled.p`
  font-weight: lighter;
  color: ${({ theme }) => theme.quibblePlaceholderGrey};
  margin-bottom: 8px;
  font-size: 14px;
`;

const ColTitle = styled.span`
  font-weight: 300;
  text-transform:uppercase;
`;

const StyledPropertyCol = styled.span`
font-weight: 300;
text-transform:uppercase;
.ant-table-thead > tr > th, 
.ant-table-tbody > tr > td {
  width: 50px;
`;

const StyledTable = styled(Table)`
width: 100%;
.ant-table-thead > tr > th, 
.ant-table-tbody > tr > td {
  border-right: none !important; 
  border-left: none !important;

}

.ant-table-thead > tr > th{
  background-color: white;
}

.ant-table-container{
  border: none !important; 
}

.ant-table-container table{
  border: none !important; 
}

`;

const StyledSpan = styled.span`
  font-weight: 300;
`;

const Checkboxes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size:14px;
  font-weight: 400;

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin: 0;
  }
`;

const StyledInput = styled(Input)`
  font-weight: 300;
  text-align: center;
`;

const RangeInput = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const SearchInput = styled.input`
  width: ${({sidebarCollapsed}) => sidebarCollapsed ? '300px' : '250px'};
  height: 50px;
  padding: 10px;
  outline: none;
  border-radius: 4px;
  border: 1px solid #CDCDCD;
  font-size: ${({theme}) => theme.s4};

  ::placeholder {
    color: #B0B0B0;
    opacity: 1; /* Firefox */
  }
`;


const SearchImage = styled.img`
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: ${({sidebarCollapsed}) => sidebarCollapsed ? '10px' : '20px'};
  margin: auto;
`;

  
/*** Get and set properties data */
const BookingFeed = ({sidebarCollapsed}) => {

  /** Default state */
  const [state, setState] = useState({
    loading: false,
    bordered: true,
    position: "bottom",
    size: "small",
    showHeader: true,
    listView: false,
  });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [propertyFilters, setPropertyFilters] = useState([]);
  const [totalFilters, setTotalFilters] = useState(0);
  const [showFilters, setShowFilters] = useState(false);


  const [leadTime, setLeadTime] = useState(0);
  const [night, setNight] = useState(0);
  const [revenueRange, setRevenueRange] = useState([0, 50000]);
  const [rentRange, setRentRange] = useState([0, 50000]);

  const [status, setStatus] = useState([]);

  const [searchString, setSearchString] = useState('');
  const [uniqueStatuses, setUniqueStatuses] = useState([]);

  /**  Condition to get  booking feed */
  let userId = localStorage.getItem("user_id");
  let token = localStorage.getItem("token");
  const [values, setValues] = useState({
    userId: userId,
    token: token,
    showAll: true
  });

  /*** Get table data */
  const dispatch = useDispatch();
  const { reservationData, loader } = useSelector(({ reservation }) => reservation);

  useEffect(() => {
    setLoading(true);
    setPropertyFilters([]);
    setData([]);
  }, [])

  useEffect(() => {
    if (_.isArray(reservationData?.bookingFeed)) {
      setData(reservationData.bookingFeed);
      setLoading(false);
      
      // Extract unique statuses
      const uniqueStatusesSet = new Set();
      reservationData.bookingFeed.forEach(item => {
        if (item.status) {
          uniqueStatusesSet.add(item.status);
        }
      });
      const uniqueStatusArray = Array.from(uniqueStatusesSet);
      setUniqueStatuses(uniqueStatusArray);
    }
  }, [reservationData.bookingFeed]);

  useEffect(() => {
    setTotal(reservationData.total);
  }, [reservationData.total])

  useEffect(() => {
    let totalFiltersCount = 0;

    if (values?.bookedDate) totalFiltersCount = totalFiltersCount + 1;
    if (values?.checkInDate && values?.checkOutDate) totalFiltersCount = totalFiltersCount + 1;
    if (values?.properties) totalFiltersCount = totalFiltersCount + values?.properties?.length;

    setTotalFilters(totalFiltersCount);
    setLoading(true);

    dispatch(getBookingFeed(values));
  }, [values])

  useEffect(() => {
    const orderedFilters = _.orderBy(reservationData?.properties,
      [property => property?.title?.toLowerCase()], ['asc']);
    if (propertyFilters?.length === 0) setPropertyFilters(orderedFilters);
  }, [reservationData.properties]);

  const applyFilters = ({ leadTime, night, rentRange, revenueRange, status }) => {
    if (!reservationData?.bookingFeed) {
      return;
    }
    const filteredData = reservationData.bookingFeed.filter(item => {
      const checkInDate = moment(item.checkIn);
      const bookedDate = moment(item.bookedDate);
      const itemLeadTime = checkInDate.diff(bookedDate, 'days');
      const rentWithinRange = item.totalRent >= rentRange[0] && item.totalRent <= rentRange[1];
      const revenueWithinRange = item.totalPayoutAmount >= revenueRange[0] && item.totalPayoutAmount <= revenueRange[1];
      const statusMatch = status?.length === 0 || status?.includes(item.status);
      
      const leadTimeMatch = leadTime === 0 || itemLeadTime === leadTime - 1;
      const nightMatch = night === 0 || item.bookedDays === night;

      return leadTimeMatch && nightMatch && rentWithinRange && revenueWithinRange && statusMatch;      

    });
    setData(filteredData);
  };

  useEffect(() => {
    applyFilters({ leadTime, night, rentRange, revenueRange, status });
  }, [leadTime, night, rentRange, revenueRange, status]);
  
  const statusFilters = uniqueStatuses.map(statusValue => ({
    text: statusValue.charAt(0).toUpperCase() + statusValue.slice(1),
    value: statusValue,
  }));

  /*** Set table header */
  const columns = [
    {
      title: () => <StyledPropertyCol >Property</StyledPropertyCol>,
      dataIndex: "propertyName",
      key: "propertyName",
      sorter: (a, b) => a.propertyName > b.propertyName ? 1 : a.propertyName < b.propertyName ? -1 : 0,    
      fixed: "left",
      render: (text, record) => (
        <Link
          to={record.propertyId ?  {
            pathname: "/market-analytics/property-details",
            state: {propertyId: record.propertyId, date: new Date(now)}
          } : ''}
          style={{color: "#00A4E6"}}
        >
          {text}
        </Link>
      ),
    },
    {
      title: () => <ColTitle>Booking Date</ColTitle>,
      dataIndex: "bookedDate",
      key: "bookedDate",
      sorter: (a, b) => new Date(a.bookedDate) - new Date(b.bookedDate),
      fixed: "left",
      // align: "center",
      render: (text) => <StyledSpan>{text.split("T")[0]}</StyledSpan>,
    },
    {
      title: () => <ColTitle>Check-In</ColTitle>,
      dataIndex: "checkIn",
      key: "checkIn",
      sorter: (a, b) => +new Date(a.checkIn) - +new Date(b.checkIn),
      fixed: "left",
      // align: "center",
      render: (text) => {
        const formattedDate = `${text} ${moment(text).format('ddd')}`;
        return <StyledSpan>{formattedDate}</StyledSpan>;
      },
    },
    {
      title: () => <ColTitle>Check-Out</ColTitle>,
      dataIndex: "checkOut",
      key: "checkOut",
      sorter: (a, b) => +new Date(a.checkOut) - +new Date(b.checkOut),
      fixed: "left",
      // align: "center",
      render: (text) => {
        const formattedDate = `${text} ${moment(text).format('ddd')}`;
        return <StyledSpan>{formattedDate}</StyledSpan>;
      },
    },
    {
      title: () => <ColTitle>Lead time</ColTitle>,
      dataIndex: "leadTime",
      key: "leadTime",
      sorter: (a, b) => a.leadTime - b.leadTime,
      fixed: "left",
      align: "right",
      render: (text) => <StyledSpan>{text}</StyledSpan>,
    },
    {
      title: () => <ColTitle>Nights</ColTitle>,
      dataIndex: "bookedDays",
      key: "bookedDays",
      sorter: (a, b) => a.bookedDays - b.bookedDays,
      fixed: "left",
      align: "right",
      render: (text) => <StyledSpan>{text}</StyledSpan>,
    },
    {
      title: () => <ColTitle>Revenue</ColTitle>,
      dataIndex: "totalPayoutAmount",
      key: "totalPayoutAmount",
      sorter: (a, b) => a.totalPayoutAmount - b.totalPayoutAmount,
      fixed: "left",
      align: "right",
      render: (text) => <StyledSpan>{currencyFormatter(text)}</StyledSpan>,
    },
    {
      title: () => <ColTitle>Rent</ColTitle>,
      dataIndex: "totalRent",
      key: "totalRent",
      sorter: (a, b) => a.totalRent - b.totalRent,
      fixed: "left",
      align: "right",
      render: (text) => <StyledSpan>{currencyFormatter(text)}</StyledSpan>,
    },
    {
      title: () => <ColTitle>ADR</ColTitle>,
      dataIndex: "adr",
      key: "adr",
      sorter: (a, b) => a.adr - b.adr,
      fixed: "left",
      align: "right",
      render: (text) => <StyledSpan>{currencyFormatter(text)}</StyledSpan>,
    },
    {
      title: () => <ColTitle>Status</ColTitle>,
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => (a.status > b.status ? 1 : a.status < b.status ? -1 : 0),
      fixed: "left",
      align: "center",
      render: (text) => (
        <Tag color={text === 'canceled' ? 'red' : text === 'confirmed' || text === 'reserved' ? 'green' : 'blue'}>
          {text.trim()}
        </Tag>
      ),
      filters: statusFilters,
      onFilter: (value, record) => record.status === value,
    },
  ];

  const customExpandIcon = ({ expanded, onExpand, record }) => (
    expanded ? 
      <UpOutlined onClick={e => onExpand(record, e)} /> : 
      <DownOutlined onClick={e => onExpand(record, e)} />
  );
  

  const filterByPropertyProps = {
    treeData: propertyFilters,
    treeCheckable: true,
    placeholder: 'All Properties',
    allowClear: true,
    disabled: propertyFilters?.length === 0 ? true : false,
    showSearch: false,
    showArrow: true,
    value: values?.properties,
    onChange: (value) => {
      setValues({
        ...values,
        properties: value,
        page: 1,
      })
      setPage(1);
    },
    style: {
      width: '100%'
    }
  }

  const onCheckInChange = (date, dateString) => {
    setValues({
      ...values,
      checkInDate: dateString,
      page: 1,
    });
    setPage(1);
  };

  const onCheckOutChange = (date, dateString) => {
    setValues({
      ...values,
      checkOutDate: dateString,
      page: 1,
    });
    setPage(1);
  };

  const handleDisableDates = (date, isCheckIn) => {
    if (isCheckIn) {
      if (values?.checkOutDate) {
        return date && date >= moment(values.checkOutDate);
      }
      return false;
    } else {
      if (values?.checkInDate) {
        return date && date <= moment(values?.checkInDate);
      }
      return false;
    }
  }

  const toggleFilters = (e) =>{
    e.preventDefault();
    setShowFilters( prev => !prev);
  }

  const handleSliderChange = (value, type) => {
    if (type === "leadTime") setLeadTime(value);
    if (type === "night") setNight(value);
    if (type === "revenueRange") setRevenueRange(value);
    if (type === "rentRange") setRentRange(value);

    applyFilters({ leadTime: value, night, rentRange, revenueRange });

  };

  const handleInputChange = (e, type) => {
    const value = Number(e.target.value);
    if (!isNaN(value)) {
      if (type === "leadTime") setLeadTime(value);
      if (type === "night") setNight(value);
    }
  };

  const handleRangeInputChange = (e, type, index) => {
    const value = Number(e.target.value.replace(/[^0-9]/g, ''));
    if (!isNaN(value)) {
      if (type === "revenueRange") {
        const newRange = [...revenueRange];
        newRange[index] = value;
        setRevenueRange(newRange);
      }
      if (type === "rentRange") {
        const newRange = [...rentRange];
        newRange[index] = value;
        setRentRange(newRange);
      }
    }
  };

  const handleStatusChange = (statusValue) => {
    setStatus(prevStatus =>
      prevStatus.includes(statusValue)
        ? prevStatus.filter(s => s !== statusValue)
        : [...prevStatus, statusValue]
    );
  };

  const formattedAmount = (value) =>{
    return `$ ${value}`
  }

  const handleSearchChange = (e) => {
    setSearchString(e.target.value);
    const lowercasedFilter = searchString.toLowerCase();
    const filteredData = reservationData.bookingFeed.filter(item => {
      return item.propertyName.toLowerCase().includes(lowercasedFilter);
    });
    setData(filteredData);
  }
  
  
  return (
    /*** Set table data */
    <Auxiliary>
      {loader ? (
        <div className="gx-loader-view">
          <CircularProgress />
        </div>
      ) : (
        <Container>
        
            <StyledRow
              style={{
                transition: "all .5s ease-in-out",
              }}
            >
              <LeftRow>
              <StyledFilterCol>
                <FilterButton
                  onClick={toggleFilters}
                  showFilters ={showFilters}
                  disabled={loading}
                >
                  <FilterOutlined /> Filter
                </FilterButton>
              </StyledFilterCol>
              <StyledCol>
                <TreeSelect {...filterByPropertyProps} />
              </StyledCol>
              <StyledCol>
                <BookingFeedDatePicker
                  placeholder="Booked Date"
                  onChange={(date, dateString) => {
                    setValues({
                      ...values,
                      bookedDate: dateString,
                      page: 1,
                    });
                    setPage(1);
                  }}
                  disabled={loading}
                  value={
                    values?.bookedDate ? moment(values?.bookedDate) : null
                  }
                />
              </StyledCol>
              <StyledCol>
                <BookingFeedDatePicker
                  placeholder="Check-in Date"
                  onChange={onCheckInChange}
                  disabled={loading}
                  value={
                    values?.checkInDate ? moment(values?.checkInDate) : null
                  }
                  disabledDate={(date) => handleDisableDates(date, true)}
                />
              </StyledCol>
              <StyledCol>
                <BookingFeedDatePicker
                  placeholder="Check-out Date"
                  onChange={onCheckOutChange}
                  disabled={loading}
                  value={
                    values?.checkOutDate ? moment(values?.checkOutDate) : null
                  }
                  disabledDate={(date) => handleDisableDates(date, false)}
                />
              </StyledCol>
              </LeftRow>

              <StyledCol>
                <SearchInput type="search" placeholder="Search" value={searchString} onChange={handleSearchChange} sidebarCollapsed={sidebarCollapsed}/>
                <SearchImage src={searchIcon}  alt="search icon"/>
             
              </StyledCol>
          
            </StyledRow>
              
            <CardContainer>
              <FilterCard showFilters={showFilters} >
              <form>
              <Filter>
                <FilterTitle>LEAD TIME</FilterTitle>
                <StyledInput value={leadTime} onChange={(e) => handleInputChange(e, "leadTime")}
 />
                <Slider value={leadTime} max={700} min={0} onChange={(value) => handleSliderChange(value, "leadTime")}
/>                
              </Filter>

              <Filter>
                <FilterTitle>NIGHTS</FilterTitle>
                <StyledInput value={night} onChange={(e) => handleInputChange(e, "night")}/>
                <Slider value={night} max={400} min={0} onChange={(value) => handleSliderChange(value, "night")}
/>                
              </Filter>

              <Filter>
                <FilterTitle>REVENUE RANGE</FilterTitle>
                <RangeInput><StyledInput value={formattedAmount(revenueRange[0])} onChange={(e) => handleRangeInputChange(e, "revenueRange", 0)}/> to <StyledInput value={formattedAmount(revenueRange[1])} onChange={(e) => handleRangeInputChange(e, "revenueRange", 1)}/></RangeInput>
                <Slider range value={revenueRange} max={50000} min={0} step={10} onChange={(value) => handleSliderChange(value, "revenueRange")}
/>                
              </Filter>

              <Filter>
                <FilterTitle>RENT RANGE</FilterTitle>
                <RangeInput><StyledInput value={formattedAmount(rentRange[0])} onChange={(e) => handleRangeInputChange(e, "rentRange", 0)}/> to <StyledInput value={formattedAmount(rentRange[1])} onChange={(e) => handleRangeInputChange(e, "rentRange", 1)}/></RangeInput>
                <Slider range value={rentRange} max={50000} min={0} step={10} onChange={(value) => handleSliderChange(value, "rentRange")}
/>                
             
              </Filter>

              <FilterTitle>STATUS</FilterTitle>
              <Checkboxes>
                {uniqueStatuses.map((statusValue, index) => (
                  <Checkbox
                    key={index}
                    style={{ paddingLeft: '6px' }}
                    checked={status.includes(statusValue)}
                    onChange={() => handleStatusChange(statusValue)}
                  >
                    {statusValue.charAt(0).toUpperCase() + statusValue.slice(1)}
                  </Checkbox>
                ))}
              </Checkboxes>
              </form>

              </FilterCard>

                  
              <BookingFeedCard showFilters={showFilters}>
                <StyledTable
                  className="gx-table-responsive"
                  {...state}
                  columns={columns}
                  dataSource={data}
                  loading={loading}
                  pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: total,
                    showTotal: (total) =>
                      `Total ${total.toLocaleString()}  Reservations`,
                    onChange: (page, pageSize) => {
                      setPage(page);
                      setPageSize(pageSize);
                    },
                  }}
                  // size="middle"
                  expandable={{
                    expandedRowRender: (record) => (
                      <StyledExtendedRow
                      >
                        <Col style={{  width:'200px' }}>
                          <RenderActiveLogo name={record.source} />
                        </Col>
                        <Col>
                          <PLabel>Source:</PLabel>
                          <p>{record?.source || "-"}</p></Col>
                        <Col>
                          <PLabel>Currency:</PLabel>
                          <p>{record?.currency || "-"}</p>
                        </Col>
                        <Col>
                          <PLabel>Updated Date:</PLabel>
                          <p>{record?.modified?.split("T")[0] || "-"}</p>
                        </Col>
                        <Col>
                          <PLabel>Date Canceled:</PLabel>
                          <p>{record?.dateCanceled?.split("T")[0] || "-"}</p>
                        </Col>                      
                        <Col>
                          <PLabel>Channel Fee:</PLabel>
                          <p>{typeof record?.pmsCharges === 'number' ? record.pmsCharges.toFixed(0) : "-"}</p>
                          </Col>                      
                        <Col>
                          <PLabel>Ancillary</PLabel>
                          <p>{record?.ancillaryRevenue || "-"}</p>
                        </Col> 
                      </StyledExtendedRow>
                    ),
                    rowExpandable: () => true,
                    expandIcon: customExpandIcon
                  }}
                  style={{ fontWeight: 500 }}
                />
              </BookingFeedCard>
            </CardContainer>
      
        </Container>
      )}
    </Auxiliary>
  );
};

export default BookingFeed;
