import { useEffect, useState } from 'react';

import moment from 'moment';
import { RotateRightOutlined, DownOutlined, SearchOutlined, SwitcherOutlined, SnippetsOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { InputNumber, Form, Select, Input, message, Button, Switch, Divider, Tooltip } from 'antd';

import DatePickerWithFunction from 'components/ReusableComponents/DatePicker/DatePickerWithFunction';

import { useDispatch, useSelector } from 'react-redux';

import styled, { css } from 'styled-components';

import { getPortfolioGoals, getMarketGoals, getPropertyGoals } from '../../../appRedux/actions/businessTools';
import { businessTools } from '../../../services/businessTools';
import IncrementDecrementInput from '../../priceAdjustment/Optimization/components/subComponents/OptimizeDrawer/IncrementDecrementInput';
import ExpandedMarketRowRender from './ExpandedMarketRowRender';
import ExpandedPropertyRowRender from './ExpandedPropertyRowRender';
import { formatAmount } from '../../../util/formatAmount';
import SkLoaderOver from '../../../components/ReusableComponents/SkeletonLoader/SkLoaderOver';
import Pagination from '../../../components/ReusableComponents/Table/Components/Pagination';
import GoalPlanningTable from './GoalPlanningTable';
import CheckBox from '../../../components/ReusableComponents/Checkbox/CheckBox';

const { Option } = Select;

const DatePickerStyle = css`
  width: 200px;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #CDCDCD;
  margin: 0 0 0 0;
  font-family: Commissioner;
`;

const GPTables = styled.div`
  min-height: 450px;
  width: 100%;
  position: relative;
`;

const GPContainer = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;

  .ant-table-wrapper {
    width: 100%;
    margin-top: 2px;

    .ant-table-tbody > tr > td {
      padding: 4px 16px;
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-table-expanded-row {
      td {
        padding: 4px 8px !important;
      }

      .ant-table-thead .ant-table-cell {
        padding: 4px 8px;
      }
    }
  }

  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table, .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table {
    margin: 0;
  }

  .ant-table-thead .ant-table-cell {
    padding: 8px 16px;
  }
`;

const GPFilters = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;

  .ant-picker {
    margin-right: 20px;
  }
`;

const GPFilterFirst = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  
  .ant-switch {
    margin: 0 5px;
  }
`;

const GPHeader = styled.div`
  padding: 8px;
  background: #fff;
`;

const GPTitleContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  width: 100%;
`;

const AdditionalFunctionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AdditionalFunctionsDiv = styled.div`
  .ant-btn-text {
    margin: 0;
  }
`;

const GPTitle = styled.div`
  flex-grow: 1;
  color: #283334;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const GPViewType = styled.div`
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;

  .anticon {
    margin: 0 8px;
  }

  &:hover {
    color: #00A4E6;
  }
`;

const MarketSelect = styled(Select)`
  width: 300px;
  height: 48px;
  font-size: ${({theme}) => theme.s4};
`;

const GPFooter = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
`;

const InputContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 5px;

  .ant-input-number {
    width: 100px !important;
  }
`;

const BulkEditDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
  padding: 0 20px;
  border-radius: 6px;
  background: #9747FF;

  .ant-input-number {
    margin: 10px;
  }
`;

const BulkEditOccupancy = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
`;

const BulkEditADR = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
`;

const ButtonContainer = styled.div`
  flex-grow: 1;
  text-align: right;
`;

const StyledInputNumber = styled(InputNumber)`
  margin: 0;
  text-align: right;
  input {
    text-align: right;
  }
`;

function GoalPlanningV2() {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [currentPageSelectedKeys, setCurrentPageSelectedKeys] = useState([]); 
  const [viewType, setViewType] = useState('propertyView');
  const [selectedMarket, setSelectedMarket] = useState('');
  const [currentPropertyGoals, setCurrentPropertyGoals] = useState([]);
  const [currentMarketGoals, setCurrentMarketGoals] = useState([]);
  const [currentPortfolioGoals, setCurrentPortfolioGoals] = useState([]);
  const [editedOccupancyGoals, setEditedOccupancyGoals] = useState({});
  const [editedADRGoals, setEditedADRGoals] = useState({});
  const [hasRemovedValues, setHasRemovedValues] = useState(false);
  const [datePickerVal, setDatePickerVal] = useState(moment().local());
  const [searchInput, setSearchInput] = useState('');
  const [showOnlyWithGoals, setShowOnlyWithGoals] = useState(false);
  const now = moment().local();
  const month = now.month() + 1;
  const year = now.year();
  const [bulkOccupancy, setBulkOccupancy] = useState(0);
  const [bulkADR, setBulkADR] = useState(0);
  const [selectMarket, setSelectMarket] = useState([]);

  const [activePage, setActivePage] = useState(0);
  const [paginationSize, setPaginationSize] = useState(10);
  const [pageRange, setPageRange] = useState([]);

  const onSetActivePage = (type, value) => {
    if (type === 'prev') {
      setActivePage((prev) => Math.max(prev - 1, 0));
    } else if (type === 'next') {
      setActivePage((prev) => prev + 1);
    } else if (type === 'direct') {
      setActivePage(value);
    }
  };

  const setIntPaginationSize = (size) => {
    setPaginationSize(size);
  };

  useEffect(() => {
    const calculatePageRange = () => {
      const start = activePage * paginationSize;
      const end = start + paginationSize;
      setPageRange(currentPropertyGoals.slice(start, end));
    };

    calculatePageRange();
  }, [activePage, paginationSize, currentPropertyGoals, currentMarketGoals]);
  
  const dispatch = useDispatch();

  const {
    portfolioGoals,
    marketGoals,
    propertyGoals,
    isFetchingPortfolioGoals,
    isFetchingMarketGoals,
    isFetchingPropertyGoals,
  } = useSelector(state => state.businessTools);

  const handleDatePickerChange = async (value) => {
    const momentValue = moment(value);
    setDatePickerVal(momentValue);
    const selectedMonth = momentValue.month() + 1;
    const selectedYear = momentValue.year();
    dispatch(getPortfolioGoals({ month: selectedMonth, year: selectedYear }));
    dispatch(getMarketGoals({ month: selectedMonth, year: selectedYear }));
    dispatch(getPropertyGoals({ month: selectedMonth, year: selectedYear }));
  };

  const handleOccupancyGoalChange = (value, record) => {
    setEditedOccupancyGoals({
      ...editedOccupancyGoals,
      [record.id]: value,
    });
  };

  const handleADRGoalChange = (value, record) => {
    setEditedADRGoals({
      ...editedADRGoals,
      [record.id]: value,
    });
  };

  const changeViewType = () => {
    if (viewType === 'propertyView') {
      setViewType('marketView');
    } else {
      setViewType('propertyView');
    }
  };

  const updateFilteredGoals = (search, market, onlyWithOutGoals) => {
    const filterBySearch = (goals) => 
        goals.filter(goal => 
            (goal.key && goal.key.toLowerCase().includes(search.toLowerCase())) ||
            (goal.market && goal.market.toLowerCase().includes(search.toLowerCase()))
        );

    const filterByMarket = (goals) => 
        !market ? goals : goals.filter(goal => goal.market === market);

    const filterByGoalPresence = (goals) => 
        onlyWithOutGoals 
            ? goals.filter(goal => {
                const goalPlan = goal.goalPlan || {};
                return Object.keys(goalPlan).length === 0 && goalPlan.occupancy === undefined;
            }) 
            : goals;

    const filteredPropertyGoals = filterByGoalPresence(filterByMarket(filterBySearch(propertyGoals || [])));
    const filteredMarketGoals = filterByGoalPresence(filterByMarket(filterBySearch(marketGoals || [])));

    if (viewType === 'propertyView') {
        setCurrentPropertyGoals(filteredPropertyGoals);
    } else if (viewType === 'marketView') {
        setCurrentMarketGoals(filteredMarketGoals);
    }
  };

  const onMarketChange = value => {
    setSelectedMarket(value);
    updateFilteredGoals(searchInput, value, showOnlyWithGoals);
  };

  const handleSwitchChange = (checked) => {
    setShowOnlyWithGoals(checked);
    updateFilteredGoals(searchInput, selectedMarket, checked);
  };

  const handleSearchInputChange = e => {
    const inputValue = e.target.value;
    setSearchInput(inputValue);
    updateFilteredGoals(inputValue, selectedMarket, showOnlyWithGoals);
  };
  
  const onUpdateGoals = async () => {
    setIsLoading(true);
    const currentMonth = datePickerVal.month() + 1;
    const selectedYear = datePickerVal.year();
    const body = [];

    const idsToRemove = selectedRowKeys.map(key => {
        const foundItem = currentPropertyGoals.find(item => item.id === key);
        return foundItem ? foundItem.goalPlan._id : null;
    }).filter(id => id != null);

    if (hasRemovedValues && idsToRemove.length > 0) {
        try {
            const commaSeparatedIds = idsToRemove.join(',');
            const res = await businessTools.deletePropertyGoals({ ids: commaSeparatedIds });

            if (res.status === 200) {

              const selectedMonth = datePickerVal.month() + 1;
              const selectedYear = datePickerVal.year();

              dispatch(getPropertyGoals({ month: selectedMonth, year: selectedYear }));
              dispatch(getPortfolioGoals({ month: selectedMonth, year: selectedYear }));
              dispatch(getMarketGoals({ month: selectedMonth, year: selectedYear }));

              idsToRemove.forEach(id => {
                delete editedOccupancyGoals[id];
                delete editedADRGoals[id];
             });

              setHasRemovedValues(false);
            }
        } catch (e) {
            setIsLoading(false);
            message.destroy();
            message.error('Deleting goals error.');
            return;
        }
    }

    for (const key in editedOccupancyGoals) {
      if (editedOccupancyGoals.hasOwnProperty(key) && editedOccupancyGoals[key] !== 0) {
          const occupancy = editedOccupancyGoals[key] / 100;
          const propertyId = key;
          body.push({ month: currentMonth, year: selectedYear, occupancy, id: propertyId });
      }
    }

    for (const key in editedADRGoals) {
      if (editedADRGoals.hasOwnProperty(key) && editedADRGoals[key] !== 0) {
        const adr = editedADRGoals[key];
        const propertyId = key;
        const existingEntry = body.find(entry => entry.id === propertyId);
        if (existingEntry) {
            existingEntry.adr = adr;
        } else {
            body.push({ month: currentMonth, year: selectedYear, adr, id: propertyId });
        }
      }
    }

    if (body.length > 0) {
      try {
        const res = await businessTools.updatePropertyGoals({ body });

        if (res.status === 200) {
          const selectedMonth = datePickerVal.month() + 1;
          const selectedYear = datePickerVal.year();

          dispatch(getPropertyGoals({ month: selectedMonth, year: selectedYear }));
          dispatch(getPortfolioGoals({ month: selectedMonth, year: selectedYear }));
          dispatch(getMarketGoals({ month: selectedMonth, year: selectedYear }));
          message.destroy();
          message.success('Updated goals saved.');
        } else {
          message.error('Updating goals failed.');
        }
      } catch (e) {
          message.destroy();
          message.error('Updating goals error.');
      }
    } else {
      message.success('Goals deleted successfully. No updates necessary.');
    }

    setSelectedRowKeys([]);
    setEditedOccupancyGoals({});
    setEditedADRGoals({});
    setBulkOccupancy(0);
    setBulkADR(0);
    setIsLoading(false);
    setHasRemovedValues(false);
  };

  const onResetGoals = () => {
    setBulkADR(0);
    setBulkOccupancy(0);
    setEditedOccupancyGoals({});
    setEditedADRGoals({});
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onCheckBoxChange = (checked, value) => {
    if (checked) {
      setCurrentPageSelectedKeys((prev) => [...prev, value]);
      setSelectedRowKeys((prev) => [...prev, value]);
    } else {
      setCurrentPageSelectedKeys((prev) => prev.filter((key) => key !== value));
      setSelectedRowKeys((prev) => prev.filter((key) => key !== value));
    }
  };

  const handleSelectAll = (checked) => {
    if (checked) {
      const currentKeys = pageRange.map((item) => item.id);
      setCurrentPageSelectedKeys(currentKeys);
      setSelectedRowKeys((prev) => [...new Set([...prev, ...currentKeys])]);
    } else {
      const currentKeys = pageRange.map((item) => item.id);
      setCurrentPageSelectedKeys([]);
      setSelectedRowKeys((prev) => prev.filter((key) => !currentKeys.includes(key)));
    }
  };

  const rowSelection = {
    selectedRowKeys: currentPageSelectedKeys,
    onChange: onCheckBoxChange,
    columnTitle: (
      <CheckBox
        checked={currentPageSelectedKeys.length === pageRange.length}
        onCheckBoxChange={handleSelectAll}
      />
    ),
    renderCell: (checked, record) => (
      <CheckBox
        checked={checked}
        onCheckBoxChange={(checked) => onCheckBoxChange(checked, record.id)}
        value={record.id}
      />
    ),
  };

  const onRemoveValuesClick = () => {
    selectedRowKeys.forEach((key) => {
      const foundItem = currentPropertyGoals.find(item => item.id === key);
      if (foundItem) {
          setEditedOccupancyGoals((prevState) => ({
              ...prevState,
              [key]: 0,
          }));
          setEditedADRGoals((prevState) => ({
              ...prevState,
              [key]: 0,
          }));
      }
    });

    setHasRemovedValues(true);
  };

  const populateGoalsFromLastYear = () => {
    setBulkADR(0);
    setBulkOccupancy(0);
    let newOccupancyGoals = { ...editedOccupancyGoals };
    let newADRGoals = { ...editedADRGoals };
  
    currentPropertyGoals.forEach(goal => {
      if (selectedRowKeys.includes(goal.id)) {
        const currentGoal = currentPropertyGoals.find(g => g.id === goal.id);
        if (currentGoal && Object.keys(currentGoal.goalPlan).length === 0) { 
          const { lastYearOccupancy, lastYearAdr } = goal;
          const occupancy = lastYearOccupancy ? (lastYearOccupancy * 100).toFixed(0) : 0; 
          const adr = lastYearAdr ? lastYearAdr.toFixed(0) : 0; 
    
          newOccupancyGoals[goal.id] = parseInt(occupancy, 0);
          newADRGoals[goal.id] = parseInt(adr, 0);
        }
      }
    });
  
    setEditedOccupancyGoals(newOccupancyGoals);
    setEditedADRGoals(newADRGoals);
  };

  const updateBulkOccupancy = (value) => {
    setBulkOccupancy(value);
    const newOccupancyGoals = { ...editedOccupancyGoals };
    selectedRowKeys.forEach((key) => {
      const originalOccupancy = currentPropertyGoals.find(goal => goal.id === key)?.goalPlan?.occupancy * 100 || 0;
      let updatedOccupancy = originalOccupancy + value;
      updatedOccupancy = Math.max(0, Math.min(updatedOccupancy, 100));
      newOccupancyGoals[key] = updatedOccupancy;
    });
    setEditedOccupancyGoals(newOccupancyGoals);
  };

  const updateBulkADR = (value) => {
    setBulkADR(value);
    const newADRGoals = { ...editedADRGoals };
    selectedRowKeys.forEach((key) => {
      const originalADR = currentPropertyGoals.find(goal => goal.id === key)?.goalPlan?.adr || 0;
      let updatedADR = originalADR + value;
      updatedADR = Math.max(0, updatedADR);
      newADRGoals[key] = updatedADR;
    });
    setEditedADRGoals(newADRGoals);
  };

  useEffect(() => {
    async function fetchGoals() {
      dispatch(getPortfolioGoals({ month: month, year: year }));
      dispatch(getMarketGoals({ month: month, year: year }));
      dispatch(getPropertyGoals({ month: month, year: year }));
    }
    fetchGoals();
  }, [dispatch, month, year]);

  useEffect(() => {
    setCurrentPortfolioGoals(portfolioGoals);
  }, [portfolioGoals]);

  useEffect(() => {
    if (marketGoals.length) {
      const extractedMarket = marketGoals.map(item => item.key);
      setSelectMarket(extractedMarket);
    }
    setCurrentMarketGoals(marketGoals);
  }, [marketGoals]);

  useEffect(() => {
    setCurrentPropertyGoals(propertyGoals);
  }, [propertyGoals]);

  useEffect(() => {
    const filteredPropertyGoals = selectedMarket ? propertyGoals.filter(property => property.market === selectedMarket) : propertyGoals;
    setCurrentPropertyGoals(filteredPropertyGoals);
  }, [propertyGoals, selectedMarket]);

  useEffect(() => {
    const filteredMarketGoals = selectedMarket ? marketGoals.filter(market => market.key === selectedMarket) : marketGoals;
    setCurrentMarketGoals(filteredMarketGoals);
  }, [marketGoals, selectedMarket]);

  const goalPlanningPropertyColumn = [
    {
      title: 'Property',
      align: 'left',
      dataIndex: 'key',
      width: '200px',
      render: (data) => (<div>{data}</div>),
    },
    {
      title: 'City',
      align: 'left',
      dataIndex: 'market',
      width: '200px',
      render: (data) => (<div>{data}</div>)
    },
    {
      title: 'Occupancy Goal',
      align: 'right',
      width: '200px',
      dataIndex: 'goalPlan',
      render: (data, record) => {
        const occupancy = editedOccupancyGoals[record.id] !== undefined ? editedOccupancyGoals[record.id] : data.occupancy * 100;
        return (
          <Form.Item>
            <StyledInputNumber
              min={0}
              value={~~occupancy}
              onChange={(value) => handleOccupancyGoalChange(value, record)}
              formatter={(value) => (parseInt(value) === 0 ? 'Empty' : `${value}%`)}
              parser={(value) => value?.replace('%', '')}
            />
          </Form.Item>
        );
      },
    },
    {
      title: 'ADR Goal',
      align: 'right',
      dataIndex: 'goalPlan',
      width: '200px',
      render: (data, record) => {
        const initVal = data.adr ? data.adr.toFixed(0) : 0;
        const amount = editedADRGoals[record.id] !== undefined ? editedADRGoals[record.id] : initVal;
        return (
          <Form.Item>
            <StyledInputNumber
              min={0}
              value={amount}
              onChange={(value) => handleADRGoalChange(value, record)}
              formatter={(value) => (parseInt(value) == 0 ? 'Empty' : `$${value}`)}
              parser={(value) => value.replace('$', '')}
            />
          </Form.Item>
        );
      },
    },
    {
      title: 'Rent Goal',
      align: 'right',
      dataIndex: 'goalPlan',
      width: '200px',
      render: (data) => (<div>{data.rent ? `$${formatAmount(data.rent)}` : 'Empty'}</div>),
    },
    {
      title: 'Revenue Goal',
      align: 'right',
      width: '200px',
      sorter: (a, b) => (a.goalPlan?.revenue || 0) - (b.goalPlan?.revenue || 0),
      render: (data) => (<div>{data.goalPlan?.revenue ? `$${formatAmount(data.goalPlan?.revenue)}` : 'Empty'}</div>),
    },
    {
      title: 'Revpar Goal',
      align: 'right',
      width: '200px',
      sorter: (a, b) => (a.goalPlan?.revpar || 0) - (b.goalPlan?.revpar || 0),
      render: (data) => (<div>{data.goalPlan?.revpar ? `$${formatAmount(data.goalPlan?.revpar)}` : 'Empty'}</div>),
    },    
    {
      title: 'Inventory',
      align: 'right',
      width: '200px',
      dataIndex: 'inventory',
      render: (data) => (<div>{data}</div>),
      sorter: (a, b) => a.inventory - b.inventory,
    },
  ];
  
  const goalPlanningMarketColumn = [
    {
      title: 'City',
      align: 'left',
      width: '355px',
      dataIndex: 'market',
      render: (data) => (<div>{data}</div>)
    },
    {
      title: 'Occupancy Goal',
      align: 'right',
      width: '200px',
      dataIndex: 'goalPlan',
      render: (data) => (<div>{data.occupancy ? `${(data.occupancy * 100).toFixed(0)} %` : 'Empty'}</div>),
    },
    {
      title: 'ADR Goal',
      align: 'right',
      width: '200px',
      dataIndex: 'goalPlan',
      render: (data) => (<div>{data.adr ? `$${formatAmount(data.adr)}` : 'Empty'}</div>),
    },
    {
      title: 'Rent Goal',
      align: 'right',
      width: '200px',
      dataIndex: 'goalPlan',
      render: (data) => (<div>{data.rent ? `$${formatAmount(data.rent)}` : 'Empty'}</div>),
    },
    {
      title: 'Revenue Goal',
      align: 'right',
      width: '200px',
      sorter: (a, b) => (a.goalPlan?.revenue || 0) - (b.goalPlan?.revenue || 0),
      render: (data) => (<div>{data.goalPlan?.revenue ? `$${formatAmount(data.goalPlan?.revenue)}` : 'Empty'}</div>),
    },
    {
      title: 'Revpar Goal',
      align: 'right',
      width: '200px',
      sorter: (a, b) => (a.goalPlan?.revpar || 0) - (b.goalPlan?.revpar || 0),
      render: (data) => (<div>{data.goalPlan?.revpar ? `$${formatAmount(data.goalPlan?.revpar)}` : 'Empty'}</div>),
    },    
    {
      title: 'Inventory',
      align: 'right',
      width: '200px',
      dataIndex: 'inventory',
      render: (data) => (<div>{data}</div>),
      sorter: (a, b) => a.inventory - b.inventory,
    },
  ];
  
  const goalPlanningPortfolioColumn = [
    {
      title: '',
      align: 'left',
      align: 'left',
      width: '355px',
      dataIndex: '',
      render: () => (<div>My Portfolio</div>),
    },
    {
      title: 'Occupancy Goal',
      align: 'right',
      width: '200px',
      dataIndex: 'goalPlan',
      render: (data) => (<div>{data.occupancy ? `${(data.occupancy * 100).toFixed(0)} %` : 'Empty'}</div>),
    },
    {
      title: 'ADR Goal',
      align: 'right',
      width: '200px',
      dataIndex: 'goalPlan',
      render: (data) => (<div>{data.adr ? `$${formatAmount(data.adr)}` : 'Empty'}</div>),
    },
    {
      title: 'Rent Goal',
      align: 'right',
      width: '200px',
      dataIndex: 'goalPlan',
      render: (data) => (<div>{data.rent ? `$${formatAmount(data.rent)}` : 'Empty'}</div>),
    },
    {
      title: 'Revenue Goal',
      align: 'right',
      width: '200px',
      dataIndex: 'goalPlan',
      render: (data) => (<div>{data.revenue ? `$${formatAmount(data.revenue)}` : 'Empty'}</div>),
    },
    {
      title: 'Revpar Goal',
      align: 'right',
      width: '200px',
      dataIndex: 'goalPlan',
      render: (data) => (<div>{data.revpar ? `$${formatAmount(data.revpar)}` : 'Empty'}</div>),
    },
    {
      title: 'Inventory',
      align: 'right',
      width: '200px',
      dataIndex: 'goalPlan',
      render: (data) => (<div>{data.inventory ? data.inventory : 'Empty'}</div>),
    },
  ];  

  const customExpandIcon = ({ expanded, onExpand, record }) =>
    expanded ? (
      <DownOutlined style={{ color: '#00A4E6' }} onClick={(e) => onExpand(record, e)} />
    ) : (
      <DownOutlined onClick={(e) => onExpand(record, e)} />
    );
  
  return (
    <GPContainer>
      <GPFilters>
        <GPFilterFirst>
          <DatePickerWithFunction
            DatepickerStyle={DatePickerStyle}
            value={datePickerVal}
            onChangeFunc={handleDatePickerChange}
            picker="month"
            format="MMMM-YYYY"
          />
          <MarketSelect value={selectedMarket} placeholder="Select Market"  onChange={onMarketChange}>
            <Option key={'All Markets'} value={''}>All Markets</Option>
            {selectMarket.map((data) => (<Option key={data} value={data}>{data}</Option>))}
          </MarketSelect>
        </GPFilterFirst>
        <SwitchContainer>
          Only show properties without  goals
          <Switch size="small" onChange={handleSwitchChange} />
        </SwitchContainer>
        <Input
          style={{ width: '300px'}}
          placeholder="Search"
          suffix={<SearchOutlined />}
          value={searchInput}
          onChange={handleSearchInputChange}
        />
      </GPFilters>
      
      <GPHeader>
        <GPTitleContainer>
          <GPTitle>Goal Planning</GPTitle>
          <GPViewType onClick={changeViewType}>
            {viewType === 'propertyView' ? 'Market View' : 'Property View' }<RotateRightOutlined />
          </GPViewType>
        </GPTitleContainer>
        {
          selectedRowKeys.length > 0 &&
          (
            <AdditionalFunctionsContainer>
              {selectedRowKeys.length} selected
              <Divider type="vertical" style={{ borderLeft: '1px solid #283334' }} />
              <AdditionalFunctionsDiv>
                <Button type="text" onClick={onRemoveValuesClick} disabled={isLoading || isFetchingPropertyGoals || isFetchingMarketGoals || isFetchingPortfolioGoals} >
                  <SwitcherOutlined />Remove Values
                </Button>
              </AdditionalFunctionsDiv>
              <AdditionalFunctionsDiv>
                <Button type="text" onClick={populateGoalsFromLastYear} disabled={isLoading || isFetchingPropertyGoals || isFetchingMarketGoals || isFetchingPortfolioGoals} >
                  <SnippetsOutlined />Populate empty goals
                  <Tooltip 
                    title="Populate empty goals with last year's values" 
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </Button>
              </AdditionalFunctionsDiv>
            </AdditionalFunctionsContainer>
          )
        }
      </GPHeader>
      <GPTables>
      
      {
        viewType === 'propertyView' ?
        <>
          <GoalPlanningTable
            rowSelection={rowSelection}
            expandable={{
              expandedRowRender: (record) => ExpandedPropertyRowRender(record, datePickerVal),
              expandIcon: customExpandIcon,
            }}
            rowKey="id"
            pagination={false}
            isLoading={isFetchingPropertyGoals}
            columns={goalPlanningPropertyColumn}
            dataSource={pageRange}
          />
          {/* Custom Pagination Component */}
          <Pagination
            onSetActivePage={onSetActivePage}
            pageRange={pageRange}
            activePage={activePage}
            totalLength={currentPropertyGoals.length || 0} 
            isLoading={isLoading || isFetchingPropertyGoals}
            paginationSize={paginationSize}
            setIntPaginationSize={setIntPaginationSize}
          />
        </>
        :
        <>
          <GoalPlanningTable 
            expandable={{
              expandedRowRender: (record) => ExpandedMarketRowRender(record, datePickerVal),
              expandIcon: customExpandIcon,
            }}
            pagination={false} 
            isLoading={isFetchingPortfolioGoals} 
            columns={goalPlanningPortfolioColumn} 
            dataSource={currentPortfolioGoals} />
          <GoalPlanningTable
            expandable={{
              expandedRowRender: (record) => ExpandedMarketRowRender(record, datePickerVal),
              expandIcon: customExpandIcon,
            }}
            pagination={false}
            isLoading={isFetchingMarketGoals || isLoading} 
            columns={goalPlanningMarketColumn} 
            dataSource={currentMarketGoals.slice(activePage * paginationSize, (activePage + 1) * paginationSize)}
          />
          {/* Custom Pagination Component */}
          <Pagination 
            onSetActivePage={onSetActivePage}
            pageRange={pageRange}
            activePage={activePage}
            totalLength={currentMarketGoals.length || 0}
            isLoading={isLoading || isFetchingMarketGoals}
            paginationSize={paginationSize}
            setIntPaginationSize={setIntPaginationSize}
          />
        </>
      }
      <SkLoaderOver isLoading={isLoading || isFetchingPropertyGoals || isFetchingMarketGoals || isFetchingPortfolioGoals} type='table' tableRows={8} />
      </GPTables>
      <GPFooter>
        <div>
          {
            selectedRowKeys.length > 1 && (
            <BulkEditDiv>
              <b>ADJUST GOAL:</b>
              <BulkEditOccupancy>
                Occupancy
                <InputContainer>
                  <IncrementDecrementInput
                    disabled={isLoading || isFetchingPropertyGoals || isFetchingMarketGoals || isFetchingPortfolioGoals}
                    allowNegative={true}
                    inputValue={bulkOccupancy || 0}
                    onInputChange={updateBulkOccupancy}
                  />
                </InputContainer>
                %
              </BulkEditOccupancy>
              <BulkEditADR>
                ADR
                <InputContainer>
                  <IncrementDecrementInput
                    disabled={isLoading || isFetchingPropertyGoals || isFetchingMarketGoals || isFetchingPortfolioGoals}
                    allowNegative={true}
                    inputValue={bulkADR || 0}
                    onInputChange={updateBulkADR}
                  />
                </InputContainer>
                $
              </BulkEditADR>
            </BulkEditDiv>
            )
          }
          
        </div>
        <ButtonContainer>
          <Button onClick={onResetGoals} disabled={isLoading || isFetchingPropertyGoals || isFetchingMarketGoals || isFetchingPortfolioGoals} >Reset</Button>
          <Button
            type="primary" 
            disabled={isLoading || isFetchingPropertyGoals || isFetchingMarketGoals || isFetchingPortfolioGoals} 
            onClick={onUpdateGoals} 
            style={{ marginRight: '10px' }}>
              Save
          </Button>
        </ButtonContainer>
      </GPFooter>
      
    </GPContainer>
  );
}

export default GoalPlanningV2;
