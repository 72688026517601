import React from 'react';

const GoalLine = (props) => {
  const { cx, cy, value, chartPeriod } = props;

  const goalWidth = chartPeriod === 'weekly' ? "10" : "24";

  if (value > 0) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" x={cx} y={cy} width={goalWidth} height="6" viewBox="0 0 19 6" fill="none">
        <line x1="0.00701737" y1="1.00002" x2="24.0064" y2="1.16844" stroke="#FFA800" strokeWidth="2"/>
      </svg>  
    );
  }

  return null
}

export default GoalLine;
