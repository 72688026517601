import React from 'react';
import styled from 'styled-components';
import { LayoutBody } from '../../../../components/ReusableComponents/Layout/LayoutBody';
import { BodyTitle } from '../../../../components/ReusableComponents/Text/Text';
import QuibbleTable from '../../../../components/ReusableComponents/Table/QuibbleTable';
import { darkScrollbar, lightScrollbar } from '../../../../components/ReusableComponents/Scrollbar/Scrollbar';

const PropertyScoutBody = styled(LayoutBody)`
  display: flex;
  flex-direction: column;
  align-items:center;
  overflow: auto;
  ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)};
`;

const PropertyScoutTableBody = styled.div`
  width: 100%;
  max-width: 1800px;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  box-sizing: border-box;
  ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)};
`;

const PropertyTableParentDiv = styled.div`
  min-width: 1800px;
  height: fit-content;
  max-width: 1800px;
  margin: 0 0 0 0;
`;

const PropertyTitle = styled(BodyTitle)`
  width: 100%;
  max-width: 1800px;
  font-size: 2rem;
  margin: 16px 0 ;
`;

const sampleData = [
  {
    name: '8 Clevedon Cir, Bella Vista, AR 72715, USA',
    status:'Pending',
    image: null,
  },
  {
    name: '8 Clevedon Cir, Bella Vista, AR 72715, USA',
    status:'Pending',
    image: null,
  },{
    name: '8 Clevedon Cir, Bella Vista, AR 72715, USA',
    status:'Pending',
    image: null,
  },]



const ScoutProperties = () => {

  const scoutPropertyColumn = [ {
    label: 'property',
    width: '1000px',
    align: 'left',
    dataIndex: 'name'
  },
  {
    label: 'status',
    width: '400px',
    align: 'left',
    dataIndex: 'status'
  },]

  return (
    <PropertyScoutBody>
      <PropertyTitle>
        Properties
      </PropertyTitle>
      <PropertyScoutTableBody>
        <PropertyTableParentDiv>
          <QuibbleTable columns={ scoutPropertyColumn } data={ sampleData } withCheckBox={ true } pagination={ true } paginationSize={ 10 }/>
        </PropertyTableParentDiv>
      </PropertyScoutTableBody>
    </PropertyScoutBody>
  )
}

export default ScoutProperties;