

const symbol = {
  USD: '$',
  MXN: 'MX$', 
  CNY: '¥',
  EUR: '€',
  CAD: 'CA$',
  JPY: '¥',
  GBP: '£',
  NZD: 'NZ$',
  AUD: 'AU$',
}



const getCurrencySymbol = (currency='USD') => {

  return symbol[currency]
  
}

export default getCurrencySymbol;