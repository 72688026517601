import React, { useState } from 'react';
import styled from 'styled-components';
import { Slider, Tooltip } from 'antd';
import PropTypes from 'prop-types';

const StyledSliderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Label = styled.span`
  font-size: 12px;
  color: #888;
  margin: 0 8px;
  min-width: 20px;
  text-align: center;
`;

const SliderWrapper = styled.div`
  position: relative;
  flex: 1;
`;

const StyledSlider = styled(Slider)`
  margin: 0;

  .ant-slider-rail {
    background: linear-gradient(90deg, #00BBD2 0%, #FA7E7E 100%);
    height: 2px !important;
    border-radius: 1px;
  }

  .ant-slider-track {
    height: 2px !important;
    background-color: transparent !important;
  }

  .ant-slider-handle {
    height: 10px;
    width: 10px;
    border-color: ${({ isCompAverage }) => (isCompAverage ? '#9747FF' : '#00BBD2')};
    background-color: ${({ isCompAverage }) => (isCompAverage ? '#9747FF' : '#FFFFFF')};
    z-index: 2;
  }

  .ant-slider-disabled .ant-slider-track,
  .ant-slider-disabled .ant-slider-rail {
    height: 2px !important;
    background-color: transparent !important;
  }
`;

const AverageIndicator = styled.div`
  position: absolute;
  top: 40%;
  left: ${({ position }) => position}%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background-color: ${({ isCompAverage }) => (isCompAverage ? '#9747FF' : '#C6A1F5')};
  border-radius: 50%;
  z-index: 1;
`;

const EdgeLine = styled.div`
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  width: 2px;
  height: 20px;
  background: ${({ start }) => (start ? '#00BBD2' : '#FA7E7E')};
`;

const GradientSlider = ({ min, max, value, setValue, average, compAverage, step }) => {
  const tooltipFormatter = () => <span>{value.toFixed(1)}</span>;

  const handleSliderChange = (val) => {
    if (!compAverage) {
      setValue(val); // Directly pass the value instead of destructuring
    }
  };

  const averagePosition = ((average - min) / (max - min)) * 100;

  return (
    <StyledSliderContainer>
      <Label>{min}</Label>

      <SliderWrapper>
        <EdgeLine style={{ left: 0 }} start />
        <EdgeLine style={{ right: 0 }} />

        {average !== undefined && (
          <AverageIndicator position={averagePosition} isCompAverage={compAverage} />
        )}

        <Tooltip title={tooltipFormatter} placement="top">
          <StyledSlider
            min={min}
            max={max}
            value={value}
            step={step}
            onChange={handleSliderChange} // Pass the value directly to setValue
            tooltip={{ visible: false }}
            disabled={compAverage}
            isCompAverage={compAverage}
          />
        </Tooltip>
      </SliderWrapper>

      <Label>{max}</Label>
    </StyledSliderContainer>
  );
};

export default GradientSlider;
