import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { PropertyMenuDiv } from '../../CommonCSS';
import PropertyInfo from '../ComparisonModel/PropertyInfo';
import ComparisonTable from '../ComparisonModel/ComparisonTable';
import ComparisonSettings from '../ComparisonModel/ComparisonSettings';
import ComparisonTableFull from '../ComparisonModel/ComparisonTableFull';
import { currentDate } from 'constants/GlobalConstant';
import { formatDate } from '../../../../../../util/localStorageHelper';
import { getPropertyModel } from '../../../../../../appRedux/actions';
import Paywall from '../../../../MarketDetails/Paywall';
import ModelPaywall from '../ComparisonModel/ModelPaywall';
import OptimizerSwitch from '../ComparisonModel/OptimizerSwitch';
import Optimizer from '../ComparisonModel/Optimizer';

const ModelComparisonDiv = styled(PropertyMenuDiv)`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  ${({isListingCss}) => isListingCss};
  margin: 0px;
`;


const PropertyModel = ({ propertyDetails, monthYear, model, setModel, isListing=false, setMonthYear, setPropertyMenuActive }) => {

  const subscriptionPlan = useSelector(state => state.users?.authUserDetails?.subscription?.plan);
  const { propertyModel, isFetchingPropertyModel } = useSelector(({ property }) => property);

  const [showFull, setShowFull] = useState(false);
  const [filterNumber, setFilterNumber] = useState(0);
  const [selectedDate, setSelectedDate] = useState([currentDate]);
  const [showOptimizer, setShowOptimizer] = useState(false);

  const dispatch = useDispatch();

  const { comparison } = propertyModel;

  const {isSilentDynamicPricingEnabled} = propertyDetails;

  useEffect(() => {
    setSelectedDate((prev) => {
      const newDateCurrent = new Date(currentDate);
      const nextElevenMonths = new Date(newDateCurrent.setMonth(newDateCurrent.getMonth() + 11));
      if (monthYear && monthYear.getMonth() === new Date(currentDate).getMonth() && monthYear.getFullYear() === new Date(currentDate).getFullYear()) {
        return [currentDate];
      }

      if (monthYear && monthYear >= new Date(currentDate) && monthYear <= nextElevenMonths) {
        return [formatDate(monthYear)]
      }

      return prev;
    })

  }, [monthYear]);

  const showFullFunc = useCallback((value) => {
    setShowFull(value);
  }, []);

  const changeFilterNumber = useCallback((value) => { 
    setFilterNumber(value);
  }, []);

  const onSelectedDateChange = useCallback((value) => {
    setSelectedDate([value]);
    dispatch(getPropertyModel({ propertyId: propertyDetails.id, params: { ...model, price: null, date: value } }))
  }, [dispatch, model, propertyDetails.id]);


  const comparisonArray = useMemo(() => {
    if (filterNumber > 0) {
      const newComparison = [...comparison];
      // newComparison.sort((a,b) => b?.market_share - a?.market_share).slice(0,filterNumber);
      return newComparison.slice(0, filterNumber);
    }
    return comparison;
  }, [comparison, filterNumber]);

  const isListingCss = isListing ? css`
    margin: 16px 0 0 0;
    height: 790px;
  ` : css `
    margin: 32px 0 0 0;
    height: 900px;
  `;


  return (
    <>
      {
        !['RevenueOS', 'Consultancy', 'Enterprise'].includes(subscriptionPlan) ?
          (
            <Paywall />
          ) : (
            <Fragment>
              <OptimizerSwitch showOptimizer={showOptimizer} setShowOptimizer={setShowOptimizer}/>
              { showOptimizer ? <Optimizer />
              : 
              <ModelComparisonDiv isListingCss={isListingCss}>
                <PropertyInfo propertyModel={propertyModel} propertyDetails={propertyDetails} selectedDate={selectedDate[0]} model={model} setModel={setModel} isListing={isListing} />
                {showFull ? <ComparisonTableFull isListing={isListing} isFetchingPropertyModel={isFetchingPropertyModel} dataArray={comparisonArray} propertyDetails={propertyDetails}/> : <ComparisonTable isListing={isListing} isFetchingPropertyModel={isFetchingPropertyModel} dataArray={comparisonArray} propertyDetails={propertyDetails}/>}
                <ComparisonSettings monthYear={monthYear} setMonthYear={setMonthYear} filterNumber={filterNumber} showFullFunc={showFullFunc} changeFilterNumber={changeFilterNumber} selectedDate={selectedDate} setSelectedDateFunc={onSelectedDateChange} isListing={isListing} />
              </ModelComparisonDiv>}
            </Fragment>

          )
      }
    </>
  )
}
export default PropertyModel;
