import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, Select, Switch, TreeSelect} from 'antd';
import moment from 'moment';

import { formatDate } from '../../../util/localStorageHelper';
import { getUserPortfolio, userDashboard } from '../../../appRedux/actions';
import { now } from '../../../constants/GlobalConstant';
import { SearchImage, SearchInput } from '../../marketAnalytics/sharedComponents/MarketPropertyFilter/MarketPropertyFilter';
import { BodyText } from 'components/ReusableComponents/Text/Text';
import searchIcon from 'assets/images/generic/search.svg';
import { darkScrollbar, lightScrollbar } from '../../../components/ReusableComponents/Scrollbar/Scrollbar';
import { DownOutlined } from '@ant-design/icons';
import { useData } from 'util/DataStore/DataContext';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';


const { SHOW_PARENT } = TreeSelect;

const FilterBody = styled.div`
  width: 100%;
  max-width: 1800px;
  height: 48px;

 
`;

const FilterFlex = styled.div`
  width: 100%;
  min-width: 1800px;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PortionDiv = styled.div`
  width: fit-content;
  height: 48px;
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MonthYearSelect = styled(Select)`
  width: 120px;
  height: 100%;
`;


const DatePickerFilter = styled(DatePicker)`
  width: 200px;
  margin: 0px 0 0 8px;
  height: 100%;
`;

const SearchInputInsights = styled(SearchInput)`
  margin: 0 0 0 32px;
`;

const SearchMarkets = styled(TreeSelect)`
	width: 340px;
	height: 48px;
	font-weight: 600;
	margin: 0 0 0 16px;
  font-family: commissioner;
  font-weight: 300;

  .ant-select-selection-placeholder {
		color: ${({theme}) => theme.black} !important;
    font-size: 1rem;
    font-weight: 300;
	};

  .ant-select-selection-overflow {
    max-height: 40px;
    overflow: auto;
    ${({theme}) => theme.isDefault ? lightScrollbar : darkScrollbar };
  };

  .ant-select-selector {
    height: 48px;
  };

`;

const InsightsFilter = ({monthYear, setMonthYear, dateType, setDateType}) => {


  const { users, common } = useSelector(({ users, common }) => ({users, common}));
  const {portfolio} = users;
  const {markets} = portfolio;

  const [isSameStore, setIsSameStore] = useState(false);
  const [insightMarketValues, setMarketFilterValues] = useState([]);

  const {filters} = common;

  const {marketFilterValues} = filters;

  const location = useLocation();
  const {state} = location;
  const {portfolioProperties} = state || {};

  const globData = useData();

  const marketTreeDataList = useMemo(() => {
    return markets.map((parentData) => ({
      title: `${parentData.market}, ${parentData.state}`, 
      key: `${parentData.market}`,
      value:  `${parentData.market}`,
      label: `${parentData.market}`,
      children: parentData.properties.map((childData) => ({title: `${childData.name}`, key: `${childData.id}`, value: `${childData.id}`, label: `${childData.name}`})),
    }))
  }, [markets]);


  const onSameStoreChange = (e) => {
    setIsSameStore(e);
  };

  const dispatch = useDispatch();  

  useEffect(() => {

    //need this call to get market list

    const userId = localStorage.getItem("user_id");
    const token = localStorage.getItem("token");
    
    const APIParamValues = {
      month: formatDate(now),
      user_id: userId,
      token: token,
      viewType: 'rmView',
    };  

    dispatch(getUserPortfolio(APIParamValues));
  }, [dispatch]);

  useEffect(() => {
    if (!marketFilterValues?.length) return;
    setMarketFilterValues(marketFilterValues);
  }, [marketFilterValues]);

  useEffect(() => {
    if (!portfolioProperties) return;
    setMarketFilterValues(portfolioProperties);
  }, [portfolioProperties]);


  useEffect(() => {

    const dateMomentFormat =  moment(monthYear).format("YYYY-MM-DD");
    const splittedDate = dateMomentFormat.split("-");
    const yearVal = splittedDate[0];

    const dateFormat = formatDate(new Date(yearVal, splittedDate[1], 0));
    const userId = localStorage.getItem("user_id");
    const token = localStorage.getItem("token");

    const propertyList = insightMarketValues?.reduce((outputData, currentData) => {
      const getIds =  marketTreeDataList.filter((marketData) => marketData.key === currentData);
      const propertiesIds = getIds.length ? getIds[0].children.map((propertyData) => propertyData.key) : [currentData]
      return [...outputData, ...propertiesIds]
    }, []);

    const APIParamValues = {
      month: dateFormat,
      sameStore: isSameStore,
      properties: propertyList,
      user_id: userId,
      token: token,
      viewType: globData?.state.viewType,
    }      

    if (dateType === 'yearly') APIParamValues.year = yearVal;
    
    dispatch(userDashboard(APIParamValues));

  }, [monthYear, dateType, dispatch, isSameStore, insightMarketValues, marketTreeDataList, globData?.state.viewType]);

  const onDateTypeChange = (value) => {
    setDateType(value);
  };

  
  const onSelectTreeChange = (values) => {
    setMarketFilterValues(values);
  };

  const showPicker = useCallback(() => {

    const onChangePicker = (_, dateString) => {
      const splitDateString = dateString.split("-");

      if (dateType === 'monthly') {
        setMonthYear(new Date(splitDateString[0], splitDateString[1], 0));
        return;
      }
        setMonthYear(new Date(splitDateString[0], now.getMonth(), 0));
    };


    const dateMomentFormat =  moment(monthYear).format("YYYY-MM-DD");
    const splittedDate = dateMomentFormat.split("-");
    const yearVal = splittedDate[0];
    const monthVal = splittedDate[1];

    const disabledDateFunctionMonth = (current) => {

      let futureMonth = moment().add(12, "M");

      if (current && current > moment(futureMonth, "YYYY-MM-DD")) return true;

      return false;
    };

    const disabledDateFunctionYear = (current) => {
      const nowFormat = moment(now).format("YYYY-MM-DD");
      if (
        current && current > moment(nowFormat, "YYYY-MM-DD")
      ) {
        return true;
      } else {
        return false;
      }
    };

    if (dateType === "monthly") {
      return (
        <DatePickerFilter
          disabledDate={disabledDateFunctionMonth}
          format={"YYYY-MM"}
          picker="month"
          onChange={onChangePicker}
          placeholder="Select Month"
          value={moment("" + yearVal + "" + monthVal, "YYYY-MM")}
          allowClear={false}
        />
      )
    }

    return (
      <DatePickerFilter
        disabledDate={disabledDateFunctionYear}
        format='YYYY'
        picker="year"
        onChange={onChangePicker}
        placeholder="Select Year"
        value={moment(yearVal)}
        allowClear={false}
      />
    )
  }, [monthYear, dateType, setMonthYear]);


  return (
    <FilterBody>
      <FilterFlex>
      <PortionDiv>
        <MonthYearSelect
          value={dateType}
          onChange={onDateTypeChange}
          options={[
            {
              value: 'monthly',
              label: <BodyText>Monthly</BodyText>,
            },
            {
              value: 'yearly',
              label: <BodyText>Yearly</BodyText>,
            },
          ]}
        />
        {showPicker()}
        <SearchMarkets
          placeholder="Filter by Markets"
          allowClear={true}
          treeCheckable
          suffixIcon={<DownOutlined />}
          showCheckedStrategy={SHOW_PARENT}
          treeData={marketTreeDataList}
          value={insightMarketValues}
          onChange={onSelectTreeChange}
        />
      </PortionDiv>
      <PortionDiv>
        <BodyText margin=" 0 8px 0 0">
            Show same store
        </BodyText>
        <Switch checked={isSameStore} onChange={onSameStoreChange} />
        <SearchInputInsights type="search" placeholder="Search" />
        <SearchImage src={searchIcon}  alt="search icon"/>
      </PortionDiv>
      </FilterFlex>
    </FilterBody>
  )
}


export default InsightsFilter;