import React from 'react';
import styled from 'styled-components';

const activeColor = '#00A4E6';

const SwitchWrapper = styled.div`
  display: inline-flex;
  border: 2px solid ${activeColor};
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  user-select: none;

  width: 100px;
  max-height: 26px;
`;

const SwitchOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: ${(props) => (props.isActive ? 'white' : activeColor)};
  background-color: ${(props) => (props.isActive ? activeColor : 'white')};
  transition: background-color 0.3s, color 0.3s;
  flex: 1;
  text-align: center;
`;

const YesNoSwitch = ({ isChecked, onToggle }) => {
  return (
    <SwitchWrapper onClick={() => onToggle(!isChecked)}>
      <SwitchOption isActive={isChecked}>YES</SwitchOption>
      <SwitchOption isActive={!isChecked}>NO</SwitchOption>
    </SwitchWrapper>
  );
};

export default YesNoSwitch;
