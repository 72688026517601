import React from "react";
import styled, { css } from "styled-components";
import { LayoutBody } from "../../../components/ReusableComponents/Layout/LayoutBody";
import { BodyText, BodyTitle } from "../../../components/ReusableComponents/Text/Text";
import { BodyCSS } from "../../../components/ReusableComponents/Card-Body/BodyCSS";
import ButtonWithFunction from "../../../components/ReusableComponents/Button/ButtonWithFunction";

import { useDispatch, useSelector } from "react-redux";
import { getPropertyScoutSearch } from "../../../appRedux/actions";
import { darkScrollbar, lightScrollbar } from "../../../components/ReusableComponents/Scrollbar/Scrollbar";
import { debouncer, showName } from "../../../util/helper";
import SkLoaderOver from "../../../components/ReusableComponents/SkeletonLoader/SkLoaderOver";




const PropertyScoutBody = styled(LayoutBody)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient( to right bottom, rgba(27, 179, 205, 0.4) 0%, rgba(195, 161, 240, 0.15) 50%);
`;

const SearchTitle = styled(BodyTitle)`
  font-size: 2rem;
  font-family: Quicksand;
  margin: 160px 0 0 0;
`;

const SearchDiv = styled.div`
  width: 738px;
  height: 56px;
  border-radius: 12px;
  margin: 24px 0 0 0;
  background-image: linear-gradient(to right, #00BBD2 25%, #9747FF 60%);          
  background-origin: border-box;
  background-clip: content-box, border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const SearchInput = styled.input`
  width: 732px;
  height: 50px;
  outline: none;
  border-radius: 8px;

  font-size: 1rem;
  font-family: Commissioner;
  font-weight: 300;
  padding: 0 24px;
  border: none;

  ::placeholder { 
    color: #B0B0B0;
  }

`;

const RecentsDiv = styled.div`
  width: 732px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 24px 0 0 0;
`;


const RecentItemsDiv = styled(RecentsDiv)`
  width: 100%;
  gap: 8px;
`;

const RecentItem = styled.div`
  ${BodyCSS}
  width: 100%;
  height: 140px;
  border-radius: 8px;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const RecentTitle = styled.h1`
  margin: 0px;
  font-size: 1rem;
  color: black;
`;

const RecentMetricDiv = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 8px 0 0 0;
`;

const MetricDiv  = styled.div`
  width: 192px;
  height: 74px;
  border-radius: 8px;
  border: 1px solid #D9D9D9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const MetricText = styled.h1`
  font-size: 20px;
  color: ${({$color}) => $color};
  margin: 0px;
  font-weight: 400;
`;

const MetricLabel = styled(BodyText)`
  font-size: 12px;
  margin: 0px;
`;

const ImageDiv = styled.div`
  width: 108px;
  height: 74px; 
  border: 1px solid red;
`;

const ViewAll = css`
  width: 90px;
  height: 40px;
  border: 1px solid #00BBD2;
  border-radius: 8px;
  background: white;
  outline: none;
  color: #00BBD2;
  box-shadow: none;
  margin: 28px 0 0 0;
`;

const ResultDiv = styled.div`
  width: 100%;
  height: max-content;
  max-height: ${({$maxHeight}) => $maxHeight};
  background: white;
  position: absolute;
  border-radius: 4px;
  border: ${({$border}) => $border};
  top: 60px;

  display: flex;
  flex-direction: column;

  overflow: auto;
  ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)};

`;

const ListButtonCss = css`
  width: 100%;
  height: 28px;
  background: none;
  color: #283334;
  text-align: left;
  flex-direction: row;
  align-items: center;
  font-size: 1rem;
  border-radius: 0px;
  justify-content: flex-start;
  box-shadow: none;
  transition: none;
`;

const SearchLoader = styled.div`
  width: 100%;
  height: 80px;
  position: relative;
`;

const PropertyScout = () => {

  const { propertyScoutSearchList, isFetchingPropertyScoutSearch } = useSelector(({ property }) => property);

  const dispatch = useDispatch();



  const onSearchType = (e) => {
    e.preventDefault();
    const value = e.target.value;

    if (!value || value === ''){
      dispatch(getPropertyScoutSearch({ clear: true }));
      return;
    } ;

    dispatch(getPropertyScoutSearch({ params: {q: value} }));
  };
  
  const debouncedOnType = debouncer(onSearchType, 500);

  const onViewAllClick = () => {
    // const params = {
    //  q: 'new york'
    // };
    
    // dispatch(getPropertyScoutSearch({ params }));
  };


  return (
    <PropertyScoutBody>
      <SearchTitle>
        Let's see if this property is a short-term rental superstar!
      </SearchTitle>
      <SearchDiv>
        <SearchInput placeholder="Enter Property Address" onChange={debouncedOnType}/> 
        <ResultDiv $maxHeight={propertyScoutSearchList.length ? "200px" : isFetchingPropertyScoutSearch ? "200px" : "0px"} $border={propertyScoutSearchList.length ? "1px solid #D9D9D9" : isFetchingPropertyScoutSearch ? "1px solid #D9D9D9" : "none"}>
          {
            isFetchingPropertyScoutSearch ?  
            <SearchLoader>
              <SkLoaderOver isLoading={true} type="full"/>
            </SearchLoader> :
            propertyScoutSearchList.map((data, index) => (<ButtonWithFunction key={`${data?.display_name} ${index}`} ButtonStyle={ListButtonCss} value={data} label={showName(data?.display_name, 90)}/>))
          } 
        </ResultDiv>
      </SearchDiv>
      <RecentsDiv>
        <BodyTitle>
          Recents
        </BodyTitle>
        <RecentItemsDiv>
          <RecentItem>
            <RecentTitle>
              8 Clevedon Cir, Bella Vista, AR 72715, USA
            </RecentTitle>
            <RecentMetricDiv> 
              <ImageDiv>
              
              </ImageDiv>
              <MetricDiv>
                <MetricText $color="#1BB3CD">
                  $31,245
                </MetricText>
                <MetricLabel>
                  Revenue
                </MetricLabel>
              </MetricDiv>
              <MetricDiv>
                <MetricText $color="#EDB548">
                  $783
                </MetricText>
                <MetricLabel>
                  ADR
                </MetricLabel>
              </MetricDiv>
              <MetricDiv>
                <MetricText $color="#9747FF">
                  88%
                </MetricText>
                <MetricLabel>
                  Occupancy
                </MetricLabel>
              </MetricDiv>
            </RecentMetricDiv>  
          </RecentItem>
          <RecentItem>
            <RecentTitle>
              8 Clevedon Cir, Bella Vista, AR 72715, USA
            </RecentTitle>
            <RecentMetricDiv> 
              <ImageDiv>
              
              </ImageDiv>
              <MetricDiv>
                <MetricText $color="#1BB3CD">
                  $31,245
                </MetricText>
                <MetricLabel>
                  Revenue
                </MetricLabel>
              </MetricDiv>
              <MetricDiv>
                <MetricText $color="#EDB548">
                  $783
                </MetricText>
                <MetricLabel>
                  ADR
                </MetricLabel>
              </MetricDiv>
              <MetricDiv>
                <MetricText $color="#9747FF">
                  88%
                </MetricText>
                <MetricLabel>
                  Occupancy
                </MetricLabel>
              </MetricDiv>
            </RecentMetricDiv>  
          </RecentItem>
          <RecentItem>
            <RecentTitle>
              8 Clevedon Cir, Bella Vista, AR 72715, USA
            </RecentTitle>
            <RecentMetricDiv> 
              <ImageDiv>
              
              </ImageDiv>
              <MetricDiv>
                <MetricText $color="#1BB3CD">
                  $31,245
                </MetricText>
                <MetricLabel>
                  Revenue
                </MetricLabel>
              </MetricDiv>
              <MetricDiv>
                <MetricText $color="#EDB548">
                  $783
                </MetricText>
                <MetricLabel>
                  ADR
                </MetricLabel>
              </MetricDiv>
              <MetricDiv>
                <MetricText $color="#9747FF">
                  88%
                </MetricText>
                <MetricLabel>
                  Occupancy
                </MetricLabel>
              </MetricDiv>
            </RecentMetricDiv>  
          </RecentItem>
        </RecentItemsDiv>
        <ButtonWithFunction ButtonStyle={ViewAll} onClickFunc={onViewAllClick} label="View All"/>
      </RecentsDiv>
    </PropertyScoutBody>
  )
}

export default PropertyScout;