import React from 'react';
import styled from 'styled-components';
import { BodyText, BodyTitle } from '../../../../components/ReusableComponents/Text/Text';
import AdjustMetrics from './AdjustMetrics';


const AnalysisResultsDiv = styled.div`
  max-width: 1812px;
  min-width: 1812px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const Header = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const HeaderButtonsDiv = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 16px;
`;

const HeaderButton = styled.div`
  width: 155px;
  height: 40px;
  background: none;
  outline: none;
  border-radius: 4px;
  border: 1px solid #1BB3CD;
  color: #1BB3CD;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
`;

const CompetitorList = styled.div`
  width: 1438px;
  max-height: 880px;
  border-top: 1px solid #D9D9D9;
`;

const AdjustListBody = styled(Header)`
  align-items: flex-start;
`;

const ListBody = styled.div`
  width: 100%:
  height: 88px;
  display: flex;
  flex-direction: row;
  padding: 10px 16px;
  border-left: 1px solid #D9D9D9;
  border-right: 1px solid #D9D9D9;
  border-bottom: 1px solid #D9D9D9;
`;

const ListItem = styled.div`
  width: 190px;
  height: 100%; 
  display: flex;
  gap: 2px;
  flex-direction: column;
  justify-content: center;
`;

const PercentShare = styled.div`
  display: flex;
  gap: 4px;
  color: #9747FF;
`;

const ImageDiv = styled.div`
  width: 120px;
  height: 60px;
  border-radius: 4px;
  background: #697273;
  margin: 0 16px 0 0;
`;

const ItemText = styled(BodyText)`
  margin: 0px;
`;

const ListExtended = styled.div`
  width: 250px;
`;

const MetricProjectionDiv = styled.div`
  width: 894px;
  height: 792px;
  background: white;
  border-radius: 8px;
  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 18px;

  
`;

const MapsDiv = styled.div`
  width: 894px;
  height: 792px;
  background: #1BB3CD;
  border-radius: 8px;
`;

const EstimatedDiv = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
`;

const MetricDiv = styled.div`
  width: 270px;
  height: 108px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border: 1px solid #D9D9D9;
`;

const MetricValue = styled(BodyTitle)`
  color: ${({$color}) => $color};
  margin: 0px;
  font-weight: 700;
`;



const AnalysisResults = () => {
  return (
    <AnalysisResultsDiv>
      <Header>
        <BodyTitle>
          Hang tight! Our system is comparing your property to others in the area. You'll receive the report via email soon.
        </BodyTitle>
        <HeaderButtonsDiv>
          <HeaderButton>
            New Search
          </HeaderButton>
          <HeaderButton>
            Cancel
          </HeaderButton>
        </HeaderButtonsDiv>
      </Header>
      <AdjustListBody>
        <AdjustMetrics />
        <CompetitorList>
       {[...Array(10).keys()].map (() => (
        <ListBody>
            <ListItem>
              <ItemText>
                Competitor
              </ItemText>
              <PercentShare>
                ?% share
              </PercentShare>
            </ListItem>
            <ImageDiv>

            </ImageDiv>
            <ListExtended>
              <ItemText>
                Competitor
              </ItemText>
              <ItemText>
                ?% share
              </ItemText>
            </ListExtended>
            <ListExtended>
              <ItemText>
                $? Night
              </ItemText>
              <ItemText>
                Min Stay: ? nights              
              </ItemText>
            </ListExtended>
            <ListExtended>
              <ItemText>
                Bedrooms: ?
              </ItemText>
              <ItemText>
                Scenic View: ?              
              </ItemText>
            </ListExtended>
            <ListExtended>
              <ItemText>
                Rating: ?
              </ItemText>
              <ItemText>
                Reviews: ?              
              </ItemText>
            </ListExtended>
            <ListExtended>
              <ItemText>
                Jacuzzi: ?
              </ItemText>
              <ItemText>
                Pool: ?              
              </ItemText>
            </ListExtended>
          </ListBody>
       )) }
        </CompetitorList>
      </AdjustListBody>
      <AdjustListBody>
        <MetricProjectionDiv>
          <BodyTitle>
            1 Year Projection
          </BodyTitle>
          <EstimatedDiv>
            <BodyText>
              Estimated by range
            </BodyText>
          </EstimatedDiv>
          <EstimatedDiv>
            <MetricDiv>
              <MetricValue $color="#1BB3CD">
                $???
              </MetricValue>
              <BodyText>
                Revenue
              </BodyText>
            </MetricDiv>
            <MetricDiv>
              <MetricValue $color="#EDB548">
                $???
              </MetricValue>
              <BodyText>
                ADR
              </BodyText>
            </MetricDiv>
            <MetricDiv>
              <MetricValue $color="#9747FF">
                $???
              </MetricValue>
              <BodyText>
                Occupancy
              </BodyText>
            </MetricDiv>
          </EstimatedDiv>
          <BodyTitle>
            Monthly Projected Revenue
          </BodyTitle>
        </MetricProjectionDiv>
        <MapsDiv>
        
        </MapsDiv>
      </AdjustListBody>
    </AnalysisResultsDiv>
  )
}

export default AnalysisResults;

