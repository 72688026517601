import React, { memo } from 'react';
import styled from 'styled-components';
import checkboxCheck from 'assets/images/checkbox/checked.png';
import unCheckBox from 'assets/images/checkbox/unchecked.png';
import checkLine from 'assets/images/checkbox/line.png';

const CheckBoxDiv = styled.div`
  min-width: fit-content;
  width: fit-content;
  height: fit-content;
  max-height: fit-content;
  display: flex;
  jsutify-content: center;
  align-items: center;
  padding: 0px;
  border-radius: 4px;
  position: relative;
  margin: 0px;

  input[type=checkbox] {
    width:  18px;
    height: 18px;
    cursor: pointer;
    border-radius: 4px;
    accent-color: transparent;
    opacity: 0;
    z-index: 1;
  }
    
  ${({ checked, line }) => !checked && !line && `
    &:hover::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #C2ECF3; 
      pointer-events: none;
      border-radius: 4px;
      border: 1px solid #00BBD2;
    }
  `}
`;

const CheckBoxImg = styled.img`
  width: fit-content;
  height: fit-content;
  image-rendering: -webkit-optimize-contrast;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;
  margin: 0px;

`;


const CheckBox = ({checked, onCheckBoxChange, value, line=false}) => {

  const onCheckChange = (e) => {
    if (!onCheckBoxChange) return;
    const clickChecked = e.target.checked;
    onCheckBoxChange(clickChecked, value);
  };

  return (
    <CheckBoxDiv checked={checked} line={line}>
      <input checked={checked}  type='checkbox' onChange={onCheckChange} />
      {(checked && !line) && <CheckBoxImg src={checkboxCheck}/>}
      {(!checked && !line) && <CheckBoxImg src={unCheckBox}/>}
      {line && <CheckBoxImg src={checkLine}/>}
    </CheckBoxDiv>
  )
}

export default memo(CheckBox); 