import React, {useState, useRef, useCallback} from "react";
import styled from "styled-components";
// import Sidebar from '../Sidebar';
// import {footerText} from "../../util/config";
import App from "../../routes/index";

import Header from '../Header';
import {useRouteMatch} from "react-router-dom";
import { lightScrollbar, darkScrollbar } from "../../components/ReusableComponents/Scrollbar/Scrollbar";
import ProtectedRoutesErrorBoundary from "../ErrorBoundaries/ProtectedRountErrorBoundaries";
import { useSelector } from "react-redux";
import { getData } from "../../util/localStorageHelper";
// import PageFilters from "../../components/ReusableComponents/Filters/FilterV2";
import HeaderV3 from '../Header/HeaderV3';
import { useHistory } from "react-router-dom";
import Navbar from "../NavBar/Navbar";
import Paywall from "../Subscription";
import { isEmpty } from "lodash";
import MainAppContext from './MainAppContext';

 
const QuibbleLayout = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
`;

const QuibbleContentBody = styled.div`
  width: ${({dynamicWidth}) => dynamicWidth};
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 0;
  position: relative;
  background: ${({theme}) => theme.layoutBg};
`;

const QuibbleMainBody = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0px;
  ${({theme}) => theme.isDefault ? lightScrollbar : darkScrollbar }
`;

const MainApp = ({onToggleTheme}) => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(true);
  const [upgradePaywall, setUpgradePaywall] = useState(false);
  const contentRef = useRef();

  const { filters } = useSelector(({ common }) => common);
  const { authUserDetails } = useSelector(({ users }) => (users))
  let persistedFilter = getData("filters") === null ? filters : getData("filters");

  const {subscription} = authUserDetails;

  const history = useHistory();

  const newHeaderPaths = [
    '/home',
    '/setting/profile',
    '/setting/profile/plan',
    '/setting/profile/plan/payment',
    '/setting/profile/edit-profile',
    '/revenue/pricing-calendar',
    '/revenue/events-pricing',
    '/revenue/bookingFeed',
    '/businessTools/property-optimize',
    '/main/analytics/portfolio-analytics',
    '/market-analytics/property',
    '/market-analytics/property-details',
    '/market-analytics/market',
    '/market-analytics/market-details',
    '/market-analytics/market-property-details',
    '/price-adjustment',
    '/revenue/events-pricing/new',
    '/revenue/events-pricing/edit',
    '/revenue/events-pricing/event',
    '/settings/listings',
    '/settings/parameters',
    '/workingqueue',
    '/businessTools/planning',
    '/businessTools/property-scout',
    '/businessTools/property-scout/properties',
    '/businessTools/property-scout/analysis',
    '/setting/myPortfolio',
    '/revenue/bookingFeed',
    '/insights',
  ];

  const match = useRouteMatch();

  const toggleCollapseNav = useCallback(() => {
    setSidebarCollapsed((prev) => !prev);
  }, []);

  return (
    <MainAppContext.Provider value={{setUpgradePaywall}}>
      <QuibbleLayout>
        {!isEmpty(authUserDetails) && <Paywall isExpired={subscription?.status !== 'active' && subscription?.status !== 'trialing'} isUpgrade={upgradePaywall} setUpgradePaywall={setUpgradePaywall} />}
        <Navbar toggleCollapseNav={toggleCollapseNav} sidebarCollapsed={sidebarCollapsed} />
        <QuibbleContentBody dynamicWidth={sidebarCollapsed ? 'calc(100% - 68px)' : 'calc(100% - 276px)'} >
          {newHeaderPaths.includes(history.location.pathname) ? <HeaderV3 onToggleTheme={onToggleTheme} toggleCollapseNav={toggleCollapseNav} sidebarCollapsed={sidebarCollapsed}/> : (
          <Header
            onToggleTheme={onToggleTheme}
            sidebarCollapsed={sidebarCollapsed}
            contentRef={contentRef}
          />
          )}

            <ProtectedRoutesErrorBoundary viewType={persistedFilter?.viewType}>
              <QuibbleMainBody >
                {/* {history.location.pathname === '/main/analytics/overview' ? <PageFilters toggleCollapseNav={toggleCollapseNav} sidebarCollapsed={sidebarCollapsed}/> : null} */}
                <App match={match} sidebarCollapsed={sidebarCollapsed} toggleCollapseNav={toggleCollapseNav} />
              </QuibbleMainBody>
          </ProtectedRoutesErrorBoundary>
        </QuibbleContentBody>
      </QuibbleLayout>
    </MainAppContext.Provider>
  )
};
export default MainApp;

