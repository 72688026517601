import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useDispatch } from "react-redux";

import DatePickerWithFunction from 'components/ReusableComponents/DatePicker/DatePickerWithFunction';
import { getMarketAnalytics } from "appRedux/actions";
import moment from "moment";
import { FULL_MONTH_NAME } from "constants/GlobalConstant";
import searchIcon from 'assets/images/generic/search.svg';
import { Switch } from "antd";
import { BodyText } from "components/ReusableComponents/Text/Text";
import { SecondaryButton } from "../../../../components/ReusableComponents/Button/Button";
import { FilterOutlined } from "@ant-design/icons";
import { getPropertyAnalytics } from "../../../../appRedux/actions";
import { pickerMonthYear } from "../../../../constants/GlobalConstant";


const FilterBody = styled.div`
  width: 100%;
  max-width: 1800px;
  height: 48px;
`;

const FilterBodyFlex = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const DatePickerStyle = css`
  width: 200px;
  height: 100%;
  border-radius: 4px;
  border: 1px solid #CDCDCD;
  margin: 0 0 0 0;
  font-family: Commissioner;
`;

export const MarketFilterItemDiv = styled.div`
  width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
  position: relative;
`;

export const SearchInput = styled.input`
  width: 300px;
  height: 100%;
  padding: 0 0 0 8px;
  outline: none;
  border-radius: 4px;
  border: 1px solid #CDCDCD;
  font-size: ${({theme}) => theme.s4};

  ::placeholder {
    color: #B0B0B0;
    opacity: 1; /* Firefox */
  }
`;


export const SearchImage = styled.img`
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 8px;
  margin: auto;
`;

const SwitchDiv = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
`;

const FilterDiV = styled(MarketFilterItemDiv)`
  gap: 16px;
`;

const FilterButton = styled(SecondaryButton)`

  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover, &.active {
    background: ${({theme}) => theme.quibbleBlue};
    color: ${({theme}) => theme.white};
  }

`;


const MarketPropertyFilter = ({monthYear, dynamicPricing, setDyanmicPricing, setMonthYearVal, setSearchStringFunc, isProperty=false, onFilterToggle, showFilter}) => {

  const dispatch = useDispatch();

  const [isActive, setIsActive] = useState(true);
  const [isSameStore, setIsSameStore] = useState(false);
  
  const dispatchAction = isProperty ? getPropertyAnalytics : getMarketAnalytics;

  useEffect(() => {
    const params = {
      month: Number(monthYear.getMonth() + 1),
      year: Number(monthYear.getFullYear()),
      active: isActive,
      sameStore: isSameStore,
      groupByParent:true,
    };
    
    dispatch(dispatchAction({ params }));
  }, [dispatch, dispatchAction, monthYear, isActive, isSameStore]);

   //on select month
  const onDatePickerChange = (value) => {
    const dateSplit = value.split('-');
    const month = FULL_MONTH_NAME.indexOf(dateSplit[0]) + 1;
    // const formatMonth = month < 9 ? `0${month}` : month; 
    // const newDate = `${dateSplit[1]}-${formatMonth}-01`;
    const selectedDate = new Date(dateSplit[1], month-1, 1);
    setMonthYearVal(selectedDate);
    // dispatch(dispatchAction({params: {month: Number(month), year: Number(dateSplit[1])}}));
  };

  const onInputChange = (e) => {
    e.preventDefault();
    const searchString = e.target.value;
    setSearchStringFunc(searchString);
  };

  const onSameStoreChange = (e) => {
    setIsSameStore(e);
    // if (e) {
    //   dispatch(dispatchAction({params: {sameStore: true, active: isActive, month: monthYear.getMonth() + 1, year: monthYear.getFullYear() }}));
    //   return;
    // }
    //   dispatch(dispatchAction({params: {sameStore: false, active: isActive, month: monthYear.getMonth() + 1, year: monthYear.getFullYear() }}));
  };

  const onActiveChange = (e) => {
    setIsActive(e);
    // if (e) {
    //   dispatch(dispatchAction({params: {active:true,  sameStore: isSameStore, month: monthYear.getMonth() + 1, year: monthYear.getFullYear() }}));
    //   return;
    // }
    //   dispatch(dispatchAction({params: {active:false, sameStore: isSameStore, month: monthYear.getMonth() + 1, year: monthYear.getFullYear() }}));
  };

  const onDynamicPricingChange = (e) => {
    setDyanmicPricing(e);
  };

  const onFilterClick = () => {
    if (!onFilterToggle) return;
    onFilterToggle();
  };

  return (
    <FilterBody>
      <FilterBodyFlex>
        <FilterDiV>
          {isProperty && <FilterButton onClick={onFilterClick} className={showFilter && 'active'} >
            <FilterOutlined /> &nbsp; Filter
          </FilterButton>}
          <DatePickerWithFunction   DatepickerStyle={DatePickerStyle} picker="month" format={"MMMM-YYYY"}  value={moment(pickerMonthYear(monthYear), 'MMMM-YYYY')} onChangeFunc={onDatePickerChange} />
        </FilterDiV>
        <MarketFilterItemDiv>
          {isProperty && <SwitchDiv>
            <BodyText margin=" 0 8px 0 0">
              Dynamic Pricing
            </BodyText>
            <Switch checked={dynamicPricing} onChange={onDynamicPricingChange} />
          </SwitchDiv>}
          <SwitchDiv>
            <BodyText margin=" 0 8px 0 0">
              Show Same store
            </BodyText>
            <Switch checked={isSameStore} onChange={onSameStoreChange} />
          </SwitchDiv>
          {isProperty &&
            <SwitchDiv>
              
              <BodyText margin=" 0 8px 0 0">
                Active Properties Only
              </BodyText>
              <Switch checked={isActive} onChange={onActiveChange} />
            </SwitchDiv>
          }
          <SearchInput type="search" placeholder="Search" onChange={onInputChange}/>
          <SearchImage src={searchIcon}  alt="search icon"/>
        </MarketFilterItemDiv>
      </FilterBodyFlex>
    </FilterBody>
  )
}

export default MarketPropertyFilter;