import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import QuibbleTable from 'components/ReusableComponents/Table/QuibbleTable';
import { BodyText } from 'components/ReusableComponents/Text/Text';
import { Link } from "react-router-dom";
import RenderBar from '../../sharedComponents/MarketPropertyTable/RenderBar';
import ExtendRenderBar from '../../sharedComponents/MarketPropertyTable/ExtendRenderBar';
import StarRating from '../../../../components/ReusableComponents/Rating/StarRatings';
import { TableAnalyticsDiv } from '../../sharedComponents/CommonCSS';
import { useSelector } from 'react-redux';
import AdjustQueueMenu from '../../../../components/ReusableComponents/TableMenus/AdjustQueueMenu';

const MarketTableBody = styled.div`
  width: 100%;
  max-width: 1800px;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: ${({gap}) => gap};
`;

const NameLink = styled(BodyText)`
  margin: 0px;
  &:hover {
    color: ${({theme}) => theme.quibbleBlue};
  }
`;

const ExtendNameLink = styled(NameLink)`
  color: ${({theme}) => theme.expandedTableFont };
`;


const MarketAnalyticsTable = ({monthYear, searchString}) => {

  const { marketAnalytics, isFetchingMarketAnalytics} = useSelector(({ markets }) => markets);
  const [checkedValues, setCheckedValues] = useState([]);

  const MarketAnalyticsColumn =  [
    {
      label: 'market',
      width: '180px',
      align: 'left',
      dataIndex: 'key',
      customRender: (data) => (<Link to={{ pathname: "/market-analytics/market-details", state: {date: monthYear, marketName: data}}}><NameLink>{data}</NameLink></Link>),
      extendDataIndex: 'key',
      extendCustomRender: (data) => (<Link to={{ pathname: "/market-analytics/market-details", state: {date: monthYear, marketName: data}}}><ExtendNameLink>{data}</ExtendNameLink></Link>),
    },
    {
      label: 'occupancy',
      width: '286px',
      align: 'left',
      customRender: (data) => <RenderBar tooltipLabel="Occupancy" current={data?.currentYearOccupancy}  forecast={data?.forecastOccupancy} market={data?.marketOccupancy} type="occupancy"/>,
      extendDataIndex: 'lastYearOccupancy',
      extendCustomRender: (data) => <ExtendRenderBar data={data}  type="occupancy"/>,
    },
    {
      label: 'occupancy gap',
      width: '148px',
      align: 'left',
      dataIndex: 'currentYearOccupancyGap',
      customRender: (data) => (`${(data * 100).toFixed(0)}%`),
    },
    {
      label: 'adr',
      width: '286px',
      align: 'left',
      customRender: (data) => <RenderBar tooltipLabel='ADR' current={data?.currentYearAdr}  forecast={data?.forecastAdr} />,
      extendCustomRender: (data) => <ExtendRenderBar data={data?.lastYearAdr} maxValue={data?.forecastAdr} />,
    },
    {
      label: 'revenue',
      width: '286px',
      align: 'left', 
      customRender: (data) => <RenderBar tooltipLabel={'Revenue'} current={data?.currentYearTotalRevenue}  forecast={data?.forecastRevenue}/>,
      extendCustomRender: (data) => <ExtendRenderBar data={data?.lastYearTotalRevenue} maxValue={data?.forecastRevenue} />,
    },
    {
      label: 'reviews',
      width: '156px',
      align: 'left',
      customRender:(data) => <StarRating yellowLabel={data.averageMarketRating} purpleLabel={data.averageRating}/>
    },
    {
      label: 'blocked days',
      width: '138px',
      align: 'left',
      dataIndex: 'currentYearBlockedDaysShare',
      customRender: (data) => (`${(data * 100).toFixed(0)}%`),
      extendDataIndex: 'lastYearBlockedDaysShare',
      extendCustomRender: (data) => (`${(data * 100).toFixed(0)}%`),
    },
  ];


  const onCheckBoxValues = useCallback((values) => {
    setCheckedValues(values);
  }, []);

  const filterMaketAnalytics = marketAnalytics.filter((data) => data?.key?.toLowerCase().includes(searchString.toLowerCase()));

  const checkedValuesData = checkedValues.map((data) => {
    return filterMaketAnalytics[data];
  });
   
  return (
    <MarketTableBody>
      <TableAnalyticsDiv width={'1585px'} >
        <AdjustQueueMenu monthYear={monthYear} adjustQueueData={checkedValuesData} checkedRows={checkedValues.length} type="market" />
        <QuibbleTable columns={MarketAnalyticsColumn} data={filterMaketAnalytics} expandable={true} checkedRows={checkedValues} withCheckBox={true} pagination={true} paginationSize={10} isLoading={isFetchingMarketAnalytics} onCheckBoxChange={onCheckBoxValues} monthYear={monthYear} columnFilter={true} persistPage={true} />
      </TableAnalyticsDiv>
    </MarketTableBody>
    
  )
}

export default MarketAnalyticsTable;
