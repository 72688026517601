import React from 'react';

const CustomTick = ({x,y, payload, disableDates, selectedDates, setSelectedDates}) => {

  const dateValue = payload?.value;
  const dateSplit = dateValue?.split('-');
  const number = Number(dateSplit[2]);
  const isSelected = selectedDates.includes(dateValue);
  const isDisabled = disableDates.includes(dateValue);


  const onDateClick = () => {
    if (isDisabled) return;
    setSelectedDates((prev) => {
      if (prev.includes(dateValue)) {
        return prev.filter((data) => data !== dateValue);
      }
      return [...prev, dateValue]
    });
  }

  return (
      <g transform={`translate(${x},${y})`} onClick={onDateClick} style={{cursor: isDisabled ? 'initial' : 'pointer'}}>
        <text
          x={5}
          y={10}
          dy={16}
          textAnchor="end"
          fontSize="18px"
          width="20px"
          fontWeight={isSelected ? 700 : 400}
          fill={isDisabled ? '#b0b0b0' : isSelected ? '#00BBD2' : 'black'}
          style={{
            textDecoration: isDisabled ? 'line-through' : 'none',
          }}
        >
          {number}
        </text>
      </g>
  )
}

export default CustomTick;