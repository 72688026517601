import React, { useState } from 'react';
import styled from 'styled-components';
import { BodyText, BodyTitle } from "../../../../../../components/ReusableComponents/Text/Text";
import { BodyCSS } from "../../../../../../components/ReusableComponents/Card-Body/BodyCSS";
import GradientSlider from "./GradientSlider";
import { Typography, Button, message } from 'antd';
import YesNoSwitch from './YesNoSwitch';
import QuibbleTable from '../../../../../../components/ReusableComponents/Table/Table';
import { SecondaryButton } from '../../../../../../components/ReusableComponents/Button/Button';
import { BulbOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import SkLoaderOver from '../../../../../../components/ReusableComponents/SkeletonLoader/SkLoaderOver';
import OptimizationTipsModal from './OptimizationTipsModal';
import { property } from '../../../../../../services/property';
import { getPropertyModel } from '../../../../../../appRedux/actions';

const { Text } = Typography;

const OptimizerBody = styled.div`
  ${BodyCSS}
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  min-width: 1776px;
  padding: 30px;
  margin: 24px 0 0 0;
`;

const OptimizerTable = styled(QuibbleTable)`
  margin-top: 16px;

  .ant-table-thead {
    .ant-table-cell {
      padding: 0;
    }
  }
`;

const StyledTableHeader = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  padding: 16px;
  font-weight: bold;
  color: #000;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  margin-top: 20px;
`;

const TipsButton = styled(SecondaryButton)`
  margin-right: 20px;
  width: 200px;
  height: 50px;
`;

const Optimizer = () => {
  const dispatch = useDispatch();
  const { propertyDetails, propertyModel, isFetchingPropertyModel } = useSelector(({ property }) => property);

  const initialSwitchStates = {
    scenicView: propertyModel.landscape_views || false,
    jacuzzi: propertyModel.jacuzzi || false,
    pool: propertyModel.pool || false,
  };
  const initialValues = {
    imageScore: propertyModel.adjusted || 0,
    rating: propertyModel.rating_value || 0,
    reviews: propertyModel.review_count || 0,
    minStay: propertyModel.min_nights || 0,
  };

  const [switchStates, setSwitchStates] = useState(initialSwitchStates);
  const [currentValues, setCurrentValues] = useState(initialValues);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isReoptimizing, setIsReoptimizing] = useState(false);

  const showModal = () => setIsModalVisible(true);
  const handleModalClose = () => setIsModalVisible(false);

  const handleToggle = (key, checked) => {
    setSwitchStates((prev) => ({ ...prev, [key]: checked }));
  };

  const handleSliderChange = (key, value) => {
    setCurrentValues((prev) => ({ ...prev, [key]: value }));
  };

  const setSliderValue = (key, newValue) => {
    setCurrentValues((prev) => ({
      ...prev,
      [key]: newValue,
    }));
  };

  const handleReoptimize = async () => {
    setIsReoptimizing(true);

    try {
      const body = {
        bedrooms: propertyModel.bedrooms,
        imageScore: currentValues.imageScore,
        jacuzzi: switchStates.jacuzzi,
        landscapeViews: switchStates.scenicView,
        minNights: currentValues.minStay,
        pool: switchStates.pool,
        ratingValue: currentValues.rating,
        reviewCount: currentValues.reviews,
      };

      const propertyId = propertyDetails.id;

      const response = await property.optimizeProperty({ propertyId, body });

      if (response.status === 200) {
        setCurrentValues((prevValues) => ({
          ...prevValues,
          optimizedPrice: response.data.data.optimized_price,
        }));
        message.success('Optimization successful!');
      } else {
        message.error('Optimization failed.');
      }
    } catch (error) {
      console.error(error);
      message.error('Error during optimization.');
    } finally {
      setIsReoptimizing(false);
    }
  };

  const columns = [
    {
      title: '',
      dataIndex: 'attribute',
      key: 'attribute',
      width: '20%',
    },
    {
      title: <StyledTableHeader bgColor="#D2FBFF">{propertyDetails.name}</StyledTableHeader>,
      dataIndex: 'property',
      key: 'property',
      width: '25%',
    },
    {
      title: <StyledTableHeader bgColor="#F7F1FF">Comp Average</StyledTableHeader>,
      dataIndex: 'compAverage',
      key: 'compAverage',
      width: '25%',
    },
    {
      title: <StyledTableHeader bgColor="#E4F5FC">Change</StyledTableHeader>,
      dataIndex: 'change',
      key: 'change',
      width: '15%',
    },
    {
      title: <StyledTableHeader bgColor="#FFFEF3">Total</StyledTableHeader>,
      dataIndex: 'total',
      key: 'total',
      width: '15%',
    },
  ];

  const data = [
    {
      key: '1',
      attribute: 'Image Score',
      property: (
        <GradientSlider
          min={0}
          max={10}
          value={currentValues.imageScore}
          setValue={(newValue) => setSliderValue('imageScore', newValue)}
          step={0.1}
          average={propertyModel.compAverage?.adjusted || 0}
        />
      ),
      compAverage: <GradientSlider min={0} max={10} value={propertyModel.compAverage?.adjusted || 0} compAverage={true} />,
      change: (
        <Text>
          {initialValues.imageScore !== currentValues.imageScore ? (
            <>
              <span>{initialValues.imageScore}</span> →{' '}
              <span style={{ color: '#1890ff' }}>{currentValues.imageScore}</span>
            </>
          ) : (
            <span>{currentValues.imageScore}</span>
          )}
        </Text>
      ),
      total: <Text></Text>,
    },
    {
      key: '2',
      attribute: 'Rating',
      property: (
        <GradientSlider
          min={0}
          max={5}
          value={currentValues.rating}
          setValue={(newValue) => setSliderValue('rating', newValue)}
          step={0.1}
          average={propertyModel.compAverage?.rating_value || 0}
        />
      ),
      compAverage: <GradientSlider min={0} max={5} value={propertyModel.compAverage?.rating_value || 0} compAverage={true} />,
      change: (
        <Text>
          {initialValues.rating !== currentValues.rating ? (
            <>
              <span>{initialValues.rating}</span> →{' '}
              <span style={{ color: '#1890ff' }}>{currentValues.rating}</span>
            </>
          ) : (
            <span>{currentValues.rating}</span>
          )}
        </Text>
      ),
      total: <Text></Text>,
    },
    {
      key: '3',
      attribute: 'Reviews',
      property: (
        <GradientSlider
          min={0}
          max={100}
          value={currentValues.reviews}
          setValue={(newValue) => setSliderValue('reviews', newValue)}
          average={propertyModel.compAverage?.review_count || 0}
        />
      ),
      compAverage: <GradientSlider min={0} max={100} value={propertyModel.compAverage?.review_count || 0} compAverage={true} />,
      change: (
        <Text>
          {initialValues.reviews !== currentValues.reviews ? (
            <>
              <span>{initialValues.reviews}</span> →{' '}
              <span style={{ color: '#1890ff' }}>{currentValues.reviews}</span>
            </>
          ) : (
            <span>{currentValues.reviews}</span>
          )}
        </Text>
      ),
      total: <Text></Text>,
    },
    {
      key: '4',
      attribute: 'Min Stay',
      property: (
        <GradientSlider
          min={0}
          max={10}
          value={currentValues.minStay}
          setValue={(newValue) => setSliderValue('minStay', newValue)}
          average={propertyModel.compAverage?.min_nights || 0}
        />
      ),
      compAverage: <GradientSlider min={0} max={10} value={propertyModel.compAverage?.min_nights || 0} compAverage={true} />,
      change: (
        <Text>
          {initialValues.minStay !== currentValues.minStay ? (
            <>
              <span>{initialValues.minStay}</span> →{' '}
              <span style={{ color: '#1890ff' }}>{currentValues.minStay}</span>
            </>
          ) : (
            <span>{currentValues.minStay}</span>
          )}
        </Text>
      ),
      total: <Text></Text>,
    },
    {
      key: '5',
      attribute: 'Scenic View',
      property: (
        <YesNoSwitch
          isChecked={switchStates.scenicView}
          onToggle={(checked) => handleToggle('scenicView', checked)}
        />
      ),
      compAverage: <GradientSlider min={0} max={100} value={(propertyModel.compAverage?.landscape_views ?? 0) * 100} compAverage={true} />,
      change: (
        <Text style={{ color: initialSwitchStates.scenicView !== switchStates.scenicView ? '#1890ff' : 'inherit' }}>
          {switchStates.scenicView ? 'Yes' : 'No'}
        </Text>
      ),
      total: <Text></Text>,
    },
    {
      key: '6',
      attribute: 'Jacuzzi',
      property: (
        <YesNoSwitch
          isChecked={switchStates.jacuzzi}
          onToggle={(checked) => handleToggle('jacuzzi', checked)}
        />
      ),
      compAverage: <GradientSlider min={0} max={100} value={(propertyModel.compAverage?.jacuzzi ?? 0) * 100} compAverage={true} />,
      change: (
        <Text style={{ color: initialSwitchStates.jacuzzi !== switchStates.jacuzzi ? '#1890ff' : 'inherit' }}>
          {switchStates.jacuzzi ? 'Yes' : 'No'}
        </Text>
      ),
      total: <Text></Text>,
    },
    {
      key: '7',
      attribute: 'Pool',
      property: (
        <YesNoSwitch
          isChecked={switchStates.pool}
          onToggle={(checked) => handleToggle('pool', checked)}
        />
      ),
      compAverage: <GradientSlider min={0} max={100} value={(propertyModel.compAverage?.pool ?? 0) * 100} compAverage={true} />,
      change: (
        <Text style={{ color: initialSwitchStates.pool !== switchStates.pool ? '#1890ff' : 'inherit' }}>
          {switchStates.pool ? 'Yes' : 'No'}
        </Text>
      ),
      total: <Text></Text>,
    },
    {
      key: '8',
      total: (
        <Text>
          <span>${propertyModel?.price || 0}</span>
          {currentValues.optimizedPrice && (
            <>
              →{' '}<span style={{ color: '#63A80A' }}>${currentValues.optimizedPrice || '0'}</span>
            </>
          )}
        </Text>
      ),
    },
  ];

  const handleReset = () => {
    Object.keys(initialValues).forEach((key) => {
      setSliderValue(key, initialValues[key]);
    });

    setSwitchStates(initialSwitchStates);

    setCurrentValues((prevValues) => ({
      ...prevValues,
      optimizedPrice: null,
    }));
  };

  return (
    <OptimizerBody>
      <BodyTitle>Listing Optimizer</BodyTitle>
      <BodyText>
        Improving your property attributes to match with market will increase your rental price.
      </BodyText>

      {isFetchingPropertyModel ? (
        <SkLoaderOver isLoading={isFetchingPropertyModel} type="table" />
      ) : (
        <OptimizerTable
          columns={columns}
          dataSource={data}
          pagination={false}
          size="medium"
          bordered
        />
      )}

      <FooterContainer>
        <TipsButton onClick={showModal}><BulbOutlined />&nbsp;Optimization Tips</TipsButton>
        <div>
          <Button type="default" onClick={handleReset}>Reset</Button>
          <Button type="primary" loading={isReoptimizing} onClick={handleReoptimize}>
            Reoptimize
          </Button>
        </div>
      </FooterContainer>
      <OptimizationTipsModal visible={isModalVisible} onClose={handleModalClose} />
    </OptimizerBody>
  );
};

export default Optimizer;
