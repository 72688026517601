import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import ButtonWithFunction from '../../../../../../../components/ReusableComponents/Button/ButtonWithFunction';
import { useDispatch } from 'react-redux';
import { getPropertyOverviewChart } from '../../../../../../../appRedux/actions';
import { useData } from '../../../../../../../util/DataStore/DataContext';
import { BodyText } from '../../../../../../../components/ReusableComponents/Text/Text';

const ChartHeader = styled.div`
  width: 1340px;
  height: 40px;
  border: 1px solid #D9D9D9;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  margin: 12px 0 0 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
`;

const LegendDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
`;

const LegendBoxDiv = styled(LegendDiv)`
  gap: 2px;
`;

const LegendBox = styled.div`
  width: 12px;
  height: 12px;
  background: ${({$color}) => $color};
`;

const PeriodsDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const GoalLine = styled(LegendBox)`
  height: 2px;
`;

const PeriodButton = css`
  width: fit-content;
  height: fit-content;
  outline: none;
  border: none;
  background: none;
  box-shadow: none;
  color: #283334;
  font-size: 1rem;
  transition: all 0.2s linear;
  font-weight: 400;

  &:hover, &:focus {
    background: none;
    color: #00A4E6;
    transform: scale(1.1);
  }

  &.active {
    background: none;
    color: #00A4E6;
  }

`;


const PropertyOverviewGraphFilter = ({chartPeriod, setChartPeriod, propertyId, year}) => {

  const dispatch = useDispatch();
  const {state} = useData();


  useEffect(() => {
    dispatch(getPropertyOverviewChart({ params: {
      year, view: chartPeriod, viewType: state?.PROPERTY_DATA?.viewType || 'rmView', property: propertyId, includeGoalPlans: true,
    }}));
  }, [year, chartPeriod, dispatch, propertyId, state?.PROPERTY_DATA?.viewType ])


  const onPeriodClick = (value) => {
    setChartPeriod(value);
  };


  return (
    <ChartHeader>
      <LegendDiv>
        <LegendBoxDiv>
          <LegendBox $color='#C6A1F5'/>
          <BodyText>
            {year - 1}
          </BodyText>
        </LegendBoxDiv>
        <LegendBoxDiv>
          <LegendBox $color='#2ECADB'/>
          <BodyText>
            {year}
          </BodyText>
        </LegendBoxDiv>
        <LegendBoxDiv>
          <LegendBox $color='#C2ECF3'/>
          <BodyText>
            Forecast
          </BodyText>
        </LegendBoxDiv>
        <LegendBoxDiv>
          <GoalLine $color='#FFA800'/>
          <BodyText>
            Goal
          </BodyText>
        </LegendBoxDiv>
        <LegendBoxDiv>
          <LegendBox $color='#82C928'/>
          <BodyText>
            Total Rent
          </BodyText>
        </LegendBoxDiv>
        <LegendBoxDiv>
          <LegendBox $color='#008981'/>
          <BodyText>
            Fees
          </BodyText>
        </LegendBoxDiv>
      </LegendDiv>
      <PeriodsDiv>
        <ButtonWithFunction className={chartPeriod === 'daily' && 'active'} ButtonStyle={PeriodButton} value={'daily'} label={'D'} onClickFunc={onPeriodClick}/>
        <ButtonWithFunction className={chartPeriod === 'weekly' && 'active'} ButtonStyle={PeriodButton} value={'weekly'} label={'W'} onClickFunc={onPeriodClick} />
        <ButtonWithFunction className={chartPeriod === 'monthly' && 'active'} ButtonStyle={PeriodButton} value={'monthly'} label={'M'} onClickFunc={onPeriodClick} />
      </PeriodsDiv>
    </ChartHeader>
  )
}

export default PropertyOverviewGraphFilter;