import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { BellOutlined, SyncOutlined } from '@ant-design/icons';
import useQuery from '../../../components/customHook/useQuery';
import Markdown from 'react-markdown'
import moment from 'moment'
import useMutation from '../../../components/customHook/useMutation';

const NotificationDiv = styled.div`
  position: relative;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;

  border: none;
  outline: none;
  background: inherit;
  margin-right: 1rem;
  margin-left: auto;

  cursor: pointer;
  user-select: none;

  .anticon {
    padding-top: 4px;
    font-size: 21px;
    color: ${ (props) => props.open ? '#00BBD2' : '#283334' };;
    background: transparent;
  }
`;

const NotificationDropdown = styled.div`
  height: 400px;
  width: 360px;
  position: absolute;
  top: ${ (props) => props.v === 1 ? '2.35rem' : '2rem' };
  background: #fff;
  right: -1rem;
  box-shadow: 0px 2px 18px 0px #91A5A933;
  z-index: 2000;
  display: ${ (props) => props.open ? 'block' : 'none' };
  padding: 1rem;
  overflow: scroll;
  h2 {
    font-weight: 700;
    font-size: 24px;
    font-family: Commissioner;
  }
`;

const NotificationFilters = styled.div`
  margin: 1.5rem 1rem;
  display: flex;
  gap: 8px;
`;

const NotificationFilter = styled.button`
  border-radius: 100px;
  border: 1px transparent;
  padding: 8px 12px;
  font-size: 1rem;
  min-width: 44px;
  background: ${ (props) => props.selected ? '#00BBD233' : '#E8E8E8' };
  color: ${ (props) => props.selected ? '#00BBD2' : '#283334' };
  cursor: pointer;
`;

const NotificationItems = styled.div`
  margin: 1rem;
`;

const NotificationItem = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0;

  .anticon {
    font-size: 24px;
    background: #B0B0B0;
    border-radius: 100px;
    border: 1px transparent;
    padding: 12px;
    color: #fff;
  }
`;

const NotificationItemText = styled.div`
  margin-left: 8px;
  font-family: Commissioner;
  font-size: 1rem;
  font-weight: 300;

  p {
    margin-bottom: 0;
  }

  strong {
    font-weight: 500;
  }
`;

const NotificationItemDate = styled.p`
  color: #00A4E6;
  font-size: 12px;
  font-weight: 300;
`;

const NotificationCount = styled.div`
  background: #F35A22;
  font-size: 10px;
  color: #fff;
  border-radius: 100px;
  width: 1rem;
  height: 1rem;
  text-align: center;
  align-items: center;
  display: grid;
  position: absolute;
  top: -2px;
  right: -6px;
`;



const NotificationSync = ({ message, createdAt }) => {
  return (
    <NotificationItem>
      <div>
        <SyncOutlined/>
      </div>
      <NotificationItemText>
        <p><Markdown>{message}</Markdown></p>
        <NotificationItemDate>{ moment(createdAt).format("DD MMM YYYY").toUpperCase() }</NotificationItemDate>
      </NotificationItemText>
    </NotificationItem>
  )
}

const Notifications = ({ v, count }) => {
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState('ALL');
  const nRef = useRef(null);
  const dRef = useRef(null);

  const {loading, data, request, error} = useQuery({
    url:"api/alerts"
  })

  const { request: makeAsRead } = useMutation({
    url:"api/mark_as_read",
    onComplete: ()=>{
      request({}, true)
    }
  })
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  const handleOutsideClick = (e) => {
    if (!nRef?.current?.contains(e.target)) {
      setOpen(false);
    }
  };

  const filterSelected = (value) => {
    return filter === value;
  };

  const toggleNotification = (e) => {
    if (nRef?.current?.contains(e.target)
      && dRef?.current?.contains(e.target)
      && open) {
      return
    }
    makeAsRead()
    setOpen(!open)

  }

  if(loading) return null
  if(error) return null

  const list = data?.alerts?.filter((data)=> {
    if (filter === "UNREAD")   {
     return !data.isRead
    }
    return true
  }).map((data, index) => <NotificationSync key={`${index}`} { ...data }/>)
  return (
    <>
      <NotificationDiv
        onClick={ toggleNotification }
        open={ open }
        ref={ nRef }
      >
        <BellOutlined/>
        { data.totalUnread > 0 && <NotificationCount><span>{ data.totalUnread }</span></NotificationCount> }
        <NotificationDropdown v={ v } open={ open } ref={ dRef }>
          <h2>Notifications</h2>
          <NotificationFilters>
            <NotificationFilter onClick={ () => {
              setFilter('ALL')
            } } selected={ filterSelected('ALL') }>All</NotificationFilter>
            <NotificationFilter onClick={ () => {
              setFilter('UNREAD')
            } } selected={ filterSelected('UNREAD') }>Unread</NotificationFilter>
          </NotificationFilters>
          <NotificationItems>
            { list }
          </NotificationItems>
        </NotificationDropdown>
      </NotificationDiv>
    </>
  )
}

export default Notifications;
