
import axios from "axios";
import {jwtExpiration} from "../../src/constants/GlobalConstant";
import { getData, storeData } from "../util/indexDbHelper";
import serviceV2 from "../util/APIUtility";

let baseURL = process.env.REACT_APP_API_URL;
const service = axios.create({ withCredentials: false, baseURL });

const headers = {headers: {'Content-Type': 'application/json'}}
export const property = {
  list: async (data) => {
    let cachedData = await getData('userProperties', data)

    if(!cachedData){
      let dataVal  = await service.post("/api/property", data, headers);
      jwtExpiration(dataVal);
      await storeData("userProperties", dataVal.data, data)
      return dataVal;
    }

    let response = { data: cachedData }
    console.log('logging cached data >> ', response)
    return response;
  },
  syncData: async (data) => {
    let dataVal  =  await serviceV2.post("/api/users/self/sync", data, headers);
    jwtExpiration(dataVal);
    return dataVal;
  },
  propertiesReservation: async (data) => {
    let dataVal  =   await service.post("/api/propertiesReservation", data, {headers: {'Content-Type': 'application/json'}});
    jwtExpiration(dataVal);
    console.log("propertiesReservation", dataVal)
    return dataVal;
  },
  cityId: async (data) => {
    let dataVal  = await service.post("/api/get-city-id", data, headers);
    jwtExpiration(dataVal);
    return dataVal;
  },
  propertyLists: async (data) => {
    let dataVal  = await service.post("/api/property-lists", data, headers);
    jwtExpiration(dataVal);
    console.log("propertyListing response ",dataVal);
    return dataVal;
  },
  dataAnalysis: async (data) => {
    let dataVal  = await service.post("/api/property/dataAnalysis", data, headers);
    jwtExpiration(dataVal);
    return dataVal;
  },
  detail: async (data) => {
    let dataVal  =  await service.post("/api/property/detail", data, headers);
    jwtExpiration(dataVal);
    return dataVal;
  },
  update: async (data) => {
    let dataVal  = await service.post("/api/property/update", data, headers);
    jwtExpiration(dataVal);
    return dataVal;
  },
  addLogs: async (data) => {
    let dataVal  = await service.post("/api/property/addLogs", data, headers);
    jwtExpiration(dataVal);
    return dataVal;
  },
  updateBuildingTags: async (data) => {
    let dataVal  = await service.post("/api/property/updateBuildingTags", data, headers);
    jwtExpiration(dataVal);
    return dataVal;
  },
  updateChannelListings: async (data) => {
    let dataVal  = await service.post("/api/property/updateChanelIds", data, headers);
    jwtExpiration(dataVal);
    return dataVal;
  },
  removeBuildingTags: async (data) => {
    let dataVal  = await service.post("/api/property/removeBuildingTags", data, headers);
    jwtExpiration(dataVal);
    return dataVal;
  },
  downloadOwnerReport: async (data) => {
    let dataVal  = await service.post("/api/property/report", data, {...headers, responseType: 'blob'});
    jwtExpiration(dataVal);
    return dataVal;
  },
  calendar: async (options) => {
    let result = await serviceV2.get(`/api/calendar`, options);
    return result.data.data;
  },
  propertyPricing: async (options) => {
    let result = await serviceV2.get(`/api/pricing/properties`, options);
    return result.data.data;
  },
  applyRules: async (options, withSend) => {
    try {
      const applyRulesAPI = withSend ? `/api/pricing/rules/v2?send=true` : `/api/pricing/rules/v2`;
      let result = await serviceV2.post(applyRulesAPI, options);
      return result;
    } catch (e) {
      return e?.response;
    }

  },
  getPropertyDetails: async (options) => {
    const {propertyId, params} = options;
    let result = await serviceV2.get(`/api/market-analytics/properties/${propertyId}`, {params: {...params}});
    return result.data.data;
  },
  getPropertyOverviewMetrics: async (options) => {
    const {propertyId, params} = options;
    let result = await serviceV2.get(`/api/market-analytics/property/${propertyId}/topmetric`, {params: {...params}});
    return result.data.data;
  },
  getPropertyTableMetrics: async (options) => {
    const {propertyId, params} = options;
    let result = await serviceV2.get(`/api/market-analytics/property/${propertyId}/metric`, {params: {...params}});
    return result.data.data;
  },
  getPropertyModel: async (options) => {
    const {propertyId, params} = options;
    let result = await serviceV2.post(`/api/market-analytics/properties/${propertyId}/model?includeCompAverage=true`, params);
    return result.data.data;
  },
  getPropertyCompetitors: async (options) => {
    const {propertyId} = options;
    let result = await serviceV2.get(`/api/market-analytics/properties/${propertyId}/competitors`);
    return result.data.data;
  },
  replacePropertyCompetitor: async (options) => {
    const { propertyId, body } = options;
    let result = await serviceV2.post(`/api/market-analytics/properties/${propertyId}/competitors/replace`, body);
    return result;
  },
  getPropertyPricingRules: async (options) => {
    const { params } = options
    let result = await serviceV2.get(`/api/pricing/rules`, {params: { ...params }});
    return result.data.data;
  },
  getPropertyQueue: async () => {
    const result = await serviceV2.get(`/api/queue`);
    return result.data.data;
  },
  addPropertyQueue: async (options) => {
    const result = await serviceV2.post(`/api/queue`, options);
    return result;
  },
  deletePropertyQueue: async (options) => {
    const result = await serviceV2.post(`/api/queue/remove`, options);
    return result;
  },
  updateStatusQueue: async (listingId) => {
    const result = await serviceV2.post(`/api/queue/review/${listingId}`);
    return result;
  },
  getPropertyAnalytics: async (options) => {
    const { params } = options
    const result = await serviceV2.get(`/api/portfolio-analytics/occadr`, {params: { ...params }});
    return result.data.data;
  },
  getActionableQueue: async () => {
    const result = await serviceV2.get(`/api/queue/actionable`);
    return result.data.data;
  },
  getPropertyGroups: async () => {
    let result = await serviceV2.get(`/api/property-groups`);
    return result.data.data;
  },
  createPropertyGroup: async (options) => {
    const { body } = options;
    let result = await serviceV2.post(`/api/property-groups`, body);
    return result;
  },
  updatePropertyGroup: async (options) => {
    const { body, id } = options;
    let result = await serviceV2.put(`/api/property-groups/${id}`, body);
    return result;
  },
  deletePropertyGroup: async (propertyGroupId) => {
    const response = await serviceV2.delete(`/api/property-groups/${propertyGroupId}`);
    return response;
  },
  getPropertyGroupById: async (options) => {
    const {  id } = options;
    let result = await serviceV2.get(`/api/property-groups/${id}`);
    return result.data.data;
  },
  getPropertyGroupsListings: async () => {
    let result = await serviceV2.get(`/api/property-groups/listings`);
    return result.data.data;
  },
  updatePropertyParameters: async (options) => {
    const { propertyId, body } = options;
    let result = await serviceV2.put(`/api/properties/${propertyId}/intel_comp_params`, body);
    return result;
  },
  lockPropertyCompetitor: async (options) => {
    const { listingId, competitorAirBnBId } = options;
    let result = await serviceV2.post(`/api/market-analytics/properties/${listingId}/competitors/${competitorAirBnBId}/lock`);
    return result;
  },
  unlockPropertyCompetitor: async (options) => {
    const { listingId, competitorAirBnBId } = options;
    let result = await serviceV2.post(`/api/market-analytics/properties/${listingId}/competitors/${competitorAirBnBId}/unlock`);
    return result;
  },
  optimizeProperty: async (options) => {
    const {propertyId, body} = options;
    let result = await serviceV2.post(`/api/market-analytics/properties/${propertyId}/optimize`, body);
    console.log(result)
    return result;
  },
  propertyScoutSearch: async (options) => {
    const { params } = options
    let result = await serviceV2.get(`api/market-analytics/markets/search`, {params: {...params}});
    return result.data.data;
  },
};
