import React, { useCallback, useState, lazy, Suspense, useContext } from "react";
import styled, { css } from "styled-components";
import ButtonWithFunction from 'components/ReusableComponents/Button/ButtonWithFunction';
import PropertyOverview from "./subComponents/PropertyMenu/PropertyOverview/PropertyOverview";
import PropertyMenuOptimize from "./subComponents/PropertyMenu/PropertyMenuOptimize";
import PropertyContext from "./PropertyContext";

const PropertyMenuCalendar = lazy(() => import("./subComponents/PropertyMenu/PropertyMenuCalendar"));
const PropertySummary = lazy(() => import("./subComponents/PropertyMenu/PropertySummary"));
const PropertyModel = lazy(() => import("./subComponents/PropertyMenu/PropertyModel"));



const PropertyMenuBody = styled.div`
  width: 100%;
  max-width: 1776px;
  margin: 24px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PropertyMenuDiv = styled.div`
  width:  100%;
  min-height: 68px;
  height: 68px;
  display: flex;
  flex-direction: row;
  font-family: Commissioner;
`;

const PropertyMenuButton = css`
  min-width: 354.5px;
  height: 100%;
  border: none;
  outline: none;
  box-shadow: none;
  background: none;
  border-radius: unset;
  border-bottom: 1px solid #CDCDCD;
  font-family: Commissioner;
  font-size: ${({theme}) => theme.s4};
  color: ${({theme}) => theme.layoutFg};

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover, &.active {
    background: none;
    color: ${({theme}) => theme.quibbleBlue};
    border-bottom: 2px solid ${({theme}) => theme.quibbleBlue};
  }

  &:disabled {
    background: none;
    color: ${({theme}) => theme.disabledGrey};
    border-bottom: 2px solid #CDCDCD;
  }

`;

const SelectedMenuBody = styled.div`
  width: 100%;
  position: ${({position}) => position};
  height: fit-content;
  min-height: 500px;
  margin: ${({margin}) => margin};
`;

const PropertyMenu = ({propertyId, monthYear, setMonthYear, isFetchingPropertyDetails, propertyDetails}) => {

  const [propertyMenuActive, setPropertyMenuActive] = useState('Overview');
  const [pricingDates, setPricingDates] = useState([]);

  const { model, setModel } = useContext(PropertyContext);


  const onMenuSelect = useCallback((value) => {
    setPropertyMenuActive(value);
  }, []) 

  const propertyOptimize = useCallback((value) => {
    setPricingDates(value.dates)
    setPropertyMenuActive('Pricing');
  }, []);

  return (
    <PropertyMenuBody>
        <PropertyMenuDiv>
          <ButtonWithFunction className={propertyMenuActive === 'Overview' && 'active'} ButtonStyle={PropertyMenuButton} onClickFunc={onMenuSelect} value="Overview" label="Overview"/>
          <ButtonWithFunction className={propertyMenuActive === 'Summary' && 'active'} ButtonStyle={PropertyMenuButton} onClickFunc={onMenuSelect} value="Summary" label="Summary"/>
          <ButtonWithFunction className={propertyMenuActive === 'Calendar' && 'active'} ButtonStyle={PropertyMenuButton} onClickFunc={onMenuSelect} value="Calendar" label="Calendar"/>
          <ButtonWithFunction className={propertyMenuActive === 'Pricing' && 'active'} ButtonStyle={PropertyMenuButton} onClickFunc={onMenuSelect} value="Pricing" label="Pricing"/>
          <ButtonWithFunction className={propertyMenuActive === 'Model' && 'active'} ButtonStyle={PropertyMenuButton} onClickFunc={onMenuSelect} value="Model" label="Model"/>
        </PropertyMenuDiv>
        <SelectedMenuBody position={propertyMenuActive === 'Calendar' ? "unset" : 'relative'} margin={propertyMenuActive === 'Pricing' ? '0 0 0 0' : '16px 0 0 0'}>
          {propertyMenuActive === 'Overview' && <PropertyOverview propertyId={propertyId} loading={true} monthYear={monthYear} isFetchingPropertyDetails={isFetchingPropertyDetails}/>}
          {propertyMenuActive === 'Summary' && <Suspense fallback={null}> <PropertySummary propertyId={propertyId} monthYear={monthYear} propertyDetails={propertyDetails}/> </Suspense>}
          {propertyMenuActive === 'Calendar' && <Suspense fallback={null}> <PropertyMenuCalendar propertyOptimize={propertyOptimize} propertyId={propertyId} monthYear={monthYear} /> </Suspense>}
          {propertyMenuActive === 'Pricing' &&  <PropertyMenuOptimize pricingDates={pricingDates} propertyId={propertyId} monthYear={monthYear} setMonthYear={setMonthYear}/> }
          {propertyMenuActive === 'Model' && <Suspense fallback={null}> <PropertyModel  setPropertyMenuActive={setPropertyMenuActive} model={model} setModel={setModel} monthYear={monthYear} setMonthYear={setMonthYear} propertyId={propertyId} propertyDetails={propertyDetails} /> </Suspense> }
        </SelectedMenuBody>
    </PropertyMenuBody> 
  )
}

export default PropertyMenu;
