import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from "styled-components";
import DOMPurify from 'dompurify';
import { Collapse } from 'antd';
import { CardCSS } from "../../../components/CommonCSS";
import { BodyTitle } from "components/ReusableComponents/Text/Text";
import Button from 'components/ReusableComponents/Button/ButtonWithFunction';
import moment from "moment";
import { UpOutlined, DownOutlined } from "@ant-design/icons";

const CurrentPlanBody = styled.div`
  ${CardCSS}
  color: #283334;

  .ant-collapse-content {
    width: 100%;
    padding: 0;

    li {
      margin-bottom: 0.5rem;
    }
  }

  .ant-collapse-header {
    width: 10rem;
    margin-left: -16px;
  }

  .ant-collapse-header-text {
    color: #697273;
  }

  .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box{
    padding: 0 ;
  }
`;

const ButtonCSS = {
  width: '11.875rem',
  fontWeight: 300,
  fontSize: '1rem',
  height: '50px',
  margin: '1rem 0',
};

const SubTitle = styled.div`
  padding: 1rem 0;
  color: #283334;
  font-family: Commissioner;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  `

const BaseFeeText = styled.div`
  padding: 3px 0px 2rem 0px;
  color: #283334;
  font-family: Commissioner;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;  
  `

const BillingOverview = styled.div`
  color: #283334;
  font-family: Commissioner;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
`

const CollapseCSS = {
  fontSize: '1rem',
  fontWeight: 300,
  fontFamily: 'Commissioner',
}

const PlanInformation = styled.div`
  color: #283334;
  font-family: Commissioner;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 180%; 

  b {
    font-weight: 500;
  }
  
  span {
    color: #697273;
  }
`;

const ListWithOutBullets = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    margin-bottom: 0.5rem;
  }
`;

const planInformationListOne = [
  "Performance Analytics",
  "Markets Breakdown",
  "Revenue + RevPar Forcast",
  "Occupancy + ADR Watch",
]

const planInformationListTwo = [
  "Choice Model Pricing",
  "Daily Optimization",
  "Quality Score Comps",
  "Competitive Tracking",
]

const CurrentPlan = () => {
  const subscription = useSelector((state) => state.users.authUserDetails.subscription);

  const history = useHistory();
  const [collapseLabel, setCollapseLabel] = useState('Hide features');
  const [activeKey, setActiveKey] = useState(1);
  const [daysLeft, setDaysLeft] = useState(0);
  // const [monthYear, setMonthYear] = useState(new Date());

  useEffect(() => {
    if (subscription.status === 'trialing' && subscription.billingDate) {
      const expirationDate = moment(subscription.billingDate); // Parse the ISO 8601 date string
      const currentDate = moment();
      const remainingDays = expirationDate.diff(currentDate, 'days');
      setDaysLeft(remainingDays);
    }
  }, [subscription]);

  const changePlan = () => {
    history.push('/setting/profile/plan');
  }

  const toggleCollapse = () => {
    if (!activeKey) {
      setActiveKey(1)
      setCollapseLabel('Hide features');
    } else {
      setActiveKey(0)
      setCollapseLabel('Show features');
    }
  }

  const renderHTML = (htmlContent) => {
    return { __html: DOMPurify.sanitize(htmlContent) };
  };

  const customExpandIcon = ({ isActive }) => isActive ? <UpOutlined /> : <DownOutlined />;

  const billingDate = subscription?.billingDate ? moment.unix(subscription.billingTs) : null;
  const currentDate = moment();
  const billingStartDate = daysLeft === 0 ? currentDate : currentDate.add(daysLeft, 'days');


  return (
    <CurrentPlanBody>
      <BodyTitle>
        Current Plan 
        {
          subscription.status === 'trialing' && (
            <span> (Free Trial Active - {daysLeft} days left)</span>
          )
        }
      </BodyTitle>
      <SubTitle>{subscription?.plan === 'RevenueOS' ? 'RevenueOS': subscription.plan} </SubTitle>
      <BillingOverview>
      {
        subscription?.plan === 'RevenueOS' && (
          <div style={{display: 'flex', flexDirection:'column',}}>
            <span>$10 per listing/month (For listings with Dynamic Pricing enabled only.)</span>
          </div>

        )
      }
        {/* {(subscription?.price > 0 && subscription?.interval) && (`$${subscription?.price}/${subscription?.interval} billed ${subscription?.interval === 'year' ? 'annualy' : 'monthly'}`)}
        {(subscription?.price < 1) && ('Free')} */}
      </BillingOverview>
      <BillingOverview>
      {billingDate ? `Next billing on ${billingDate.format('MMMM DD, YYYY')}` : (daysLeft === 0 ? `Billing starts on ${billingStartDate.format('MMMM DD, YYYY')}` : '')}
      </BillingOverview>
      <Collapse expandIconPosition="end" bordered={false} style={CollapseCSS} ghost={true} onChange={toggleCollapse} activeKey={activeKey} expandIcon={customExpandIcon}>
        <Collapse.Panel header={collapseLabel} key={1} >
          {/* <ul>{subscription?.features?.map((feature, key) => {
            return <li key={key} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(feature) }}></li>
          })}</ul> */}
          <PlanInformation>
            <ul>
              {planInformationListOne.map((item, index) => (
                <li key={`plan-one-${index}`}>
                  <div dangerouslySetInnerHTML={renderHTML(item)} />
                </li>
              ))}
            </ul>
            <ListWithOutBullets>
              {planInformationListTwo.map((item, index) => (
                <li key={`plan-two-${index}`}>
                  <div dangerouslySetInnerHTML={renderHTML(item)} />
                </li>
              ))}
            </ListWithOutBullets>
          </PlanInformation>
          
        </Collapse.Panel>
      </Collapse>
      
      <BaseFeeText>$299/month base fee to use the platform</BaseFeeText>
      <Button onClickFunc={changePlan} style={ButtonCSS} label={'Change Plan'} />
    </CurrentPlanBody>
  )
}

export default CurrentPlan;