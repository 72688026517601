import React, { useState } from 'react';
import styled from 'styled-components';
import { LayoutBody } from '../../../../components/ReusableComponents/Layout/LayoutBody';
import {BodyTitle } from '../../../../components/ReusableComponents/Text/Text';
import AdjustMetrics from './AdjustMetrics';
import AnalysisResults from './AnalysisResults';
import { darkScrollbar, lightScrollbar } from '../../../../components/ReusableComponents/Scrollbar/Scrollbar';


const PropertyScoutBody = styled(LayoutBody)`
  display: flex;
  flex-direction: column;
  align-items: ${({justification}) => justification};
  justify-content: ${({justification}) => justification};
  overflow: auto;
  padding: 12px;

  ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)};
`;


const ScoutAnalysis = () => {

  const [analysing, setAnalysing] = useState(false);

  return (
    <PropertyScoutBody justification={analysing ? 'flex-start' : 'center'}>
      {!analysing ? <BodyTitle margin="0 0 56px 0">
        Let’s play around with the settings and see how much money this property could make.
      </BodyTitle> : null}
      {analysing ? <AnalysisResults /> : <AdjustMetrics setAnalysing={setAnalysing}/>}
    </PropertyScoutBody>
  )
}

export default ScoutAnalysis;