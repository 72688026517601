import styled from "styled-components";
import { BodyCSS } from "../../../../components/ReusableComponents/Card-Body/BodyCSS";
import { BodyText } from "../../../../components/ReusableComponents/Text/Text";
import { Fragment } from "react";

const PropertySetDiv = styled.div`
  ${BodyCSS}
  width: 360px;
  height: 720px;
  margin: 0 0 0 0;
  border: 1px solid #D9D9D9;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 16px 24px;
`;

const ImageDiv = styled.div`
  width: 300px;
  height: 150px;
  border: 1px solid #D9D9D9;
`;

const ItemDiv = styled.div`
  width: 100%;
  height: 40px;
  border: 1px solid #D9D9D9;
`;

const AnalyseButton = styled.button`
  width: 155px;
  height: 40px;
  background: #1BB3CD;
  color: white;
  outline: none;
  border: none;
  margin: 24px 0 0 0;
  cursor: pointer;
`;

const AdjustMetrics = ({setAnalysing}) => {

  const onAnalyse = () => {
    setAnalysing(true);
  };

  return (
    <Fragment>
      <PropertySetDiv>
        <BodyText>
          8 Clevedon Cir, Bella Vista, AR 72715, USA
        </BodyText>
        <ImageDiv>

        </ImageDiv>
        <ItemDiv>

        </ItemDiv>
        <ItemDiv>

        </ItemDiv>
        <ItemDiv>

        </ItemDiv>
        <ItemDiv>

        </ItemDiv>
        <ItemDiv>

        </ItemDiv>
        <ItemDiv>

        </ItemDiv>
        <ItemDiv>

        </ItemDiv>
        <AnalyseButton onClick={onAnalyse}>
          Analyze
        </AnalyseButton>
      </PropertySetDiv>
    </Fragment>
   
  )
}

export default AdjustMetrics;