import React, { useCallback } from 'react';
import lisitingPhotoPlaceholder from '../../assets/images/listingphotoplaceholder.png';
import { useData } from '../../util/DataStore/DataContext';
import moment from 'moment';

const ViewTitle = {
  rmView: "RM View",
  checkInView: "Check In",
  checkOutView: "Check Out",
}


const metric = {
  adr: 'Average Daily Rate (ADR)',
  alos: 'Average Length of Stay',
  ancillaryRevenue: 'Ancillary Revenue',
  blockedDay: 'Blocked Days',
  bookedNights: 'Booked Nights',
  ownerBooking: 'Owner Booking',
  reservation: 'Reservations',
  totalRent: 'Total Rent',
  totalRevenue: 'Total Revenue',
};

function getQuarter(number, defaultData, year, isYearOnly = false) {
  const quarterMapping = {
    1: '1st Quarter',
    2: '2nd Quarter',
    3: '3rd Quarter',
    4: '4th Quarter',
  };

  if (isYearOnly) return `Year ${ year }`;

  if (quarterMapping[number]) {
    return `${ quarterMapping[number] } ${ year }`;
  }
  return defaultData || 'Monthly Data';
}

export const ReportViewer = ({ setPayload, ...props }) => {
  // let persistedFilter = getData("filters") === null ? filters : getData("filters");
  const payload = setPayload();
  const brandLogoUrl = props.brandLogoUrl;
  const {
    property,
    month,
    year,
    currentMonthCurrentYearADR,
    currentMonthCurrentYearOccupancy,
    keyMetrics,
    keyMetricsByMonth
  } = payload;

  const { state } = useData();
  const viewType = props.viewType
  const quarter = props?.quarter || state?.quarter
  const { name, city, imageUrl } = property;
  const MonthYear = `${ month } ${ year }`;
  const { currentMonth, lastMonth } = keyMetricsByMonth;
  const {totalRent} = currentMonth || {}
  const renderMonthTable = useCallback(() => {
    const monthKeys = Object.keys(currentMonth);
    if (monthKeys.length) {
      return (
        <>
          <span id="waitfor"
                className='category-title data-title'>{ getQuarter(quarter, undefined,year || props.year, quarter === 5) }</span>
          <div className='table-header'>
						<span className='label metric'>
							Metric
						</span>
            <div className='label-div'>
							<span className='label'>
								{ getQuarter(quarter, `${ month } ${ year }`, year, quarter === 5) }
							</span>
              <span className='label'>
								{ getQuarter(quarter, `${ month } ${ year - 1 }`, year - 1, quarter === 5) }
							</span>
            </div>
          </div>

          { monthKeys.map((data) => {
            return (
              <div className='table-header row' key={ data }>
								<span className='label metric'>
									{ metric[data] || '--' }
								</span>
                <div className='label-div row'>
									<span className='label row-month'>
										{ (currentMonth[data] === '$NaN' ? '--' : currentMonth[data]) || '--' }
									</span>
                  <span className='label row-month'>
										{ (lastMonth[data] === '$NaN' ? '--' : lastMonth[data]) || '--' }
									</span>
                </div>
              </div>
            );
          }) }
        </>
      );
    }
  }, [keyMetricsByMonth, quarter, month, year]);

  const renderYearTable = useCallback(() => {
    const { currentYear, last365, lastYear } = keyMetrics;
    const yearKeys = Object.keys(currentYear);
    if (yearKeys.length) {
      return (
        <>
          <span className='category-title data-title'> Yearly Data </span>
          <div className='table-header'>
						<span className='label metric'>
							Metric
						</span>
            <div className='label-div'>
							<span className='label'>
								{ year }
							</span>
              <span className='label'>
								{ year - 1 }
							</span>
              <span className='label'>
								Last 365 DAYS
							</span>
            </div>
          </div>
          { yearKeys.map((data) => {
            return (
              <div className='table-header row' key={ data }>
								<span className='label metric'>
									{ metric[data] || '--' }
								</span>
                <div className='label-div row'>
									<span className='label row-year'>
										{ (currentYear[data] === '$NaN' ? '--' : currentYear[data]) || '--' }
									</span>
                  <span className='label row-year'>
										{ (lastYear[data] === '$NaN' ? '--' : lastYear[data]) || '--' }
									</span>
                  <span className='label row-year'>
										{ (last365[data] === '$NaN' ? '--' : last365[data]) || '--' }
									</span>
                </div>
              </div>
            );
          }) }
        </>
      );
    }
  }, [keyMetrics, year]);

  return (
    <div className="owner-report-body">

      <div className="owner-report-content">
        <div style={ { display: "flex", justifyContent: 'space-between' } }>
          <div>
            <span
              className='report-title'> { `Revenue Report - ${ getQuarter(quarter, MonthYear, year, quarter === 5) }` } </span>
            <div className='generated-date-time'>Generated { moment().format('MM-DD-YY  HH:mm:ss') }</div>
          </div>
          <div className='image-container'>
            <img src={ brandLogoUrl } style={ { height: '100px' } } className='property-image'
                 alt="brandLog"/>
          </div>
        </div>
        <div className='header-div'>
          <div className='image-container'>
            <img src={ imageUrl || lisitingPhotoPlaceholder } className='property-image' alt="property"/>
          </div>
          <div className='details-container'>
            <div className='title-div'>
              <span className='title-name'>{ name }</span>
              <span className='title-city'>{ city }</span>
            </div>
            <div className='reports-div'>
              <span
                className='category-title'> { `${ getQuarter(quarter, MonthYear, year, quarter === 5) } Data` } </span>
              <div className='current-data-container'>
                <div className='current-data'>
                  <div className='current-data-value'>{ `${ totalRent }` }</div>
                  <div className='current-data-title'>Total Rent</div>
                </div>
                <div className='current-data'>
                  <div className='current-data-value'>{ `${ currentMonthCurrentYearADR }` }</div>
                  <div className='current-data-title'>ADR</div>
                </div>
                <div className='current-data'>
                  <div className='current-data-value'>{ `${ currentMonthCurrentYearOccupancy }%` }</div>
                  <div className='current-data-title'>Occupancy</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        { quarter !== 5 && renderMonthTable(year) }
        { renderYearTable() }
        <div className='view-mode'>View Mode - { viewType ? ViewTitle[viewType] : ViewTitle[state.viewType] }</div>

        <div className='footer'>
          Powered by Quibble - https://quibblerm.com
        </div>
      </div>
    </div>
  );
};

export default ReportViewer;
