import React from 'react';
import { Modal, Collapse, Typography } from 'antd';
import { BulbOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const { Panel } = Collapse;
const { Text, Paragraph } = Typography;

const AccordionTitle = styled.div`
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 18px;
`;

const TipContent = styled.div`
  padding-left: 16px;
  font-size: 14px;
`;

const StyledCollapse = styled(Collapse)`
  .ant-collapse-header {
    display: flex;
    justify-content: space-between;
  }

  .ant-collapse-arrow {
    position: absolute;
    right: 16px;
  }

  ol {
    padding-left: 16px;
  }
`;

const OptimizationTipsModal = ({ visible, onClose }) => {
  return (
    <Modal
      visible={visible}
      title={<AccordionTitle><BulbOutlined />&nbsp;Tips</AccordionTitle>}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      <StyledCollapse accordion>
        <Panel header="Rating" key="1">
          <TipContent>
            <Paragraph>Here are some tips for improving your rental property rating:</Paragraph>
            <ol>
              <li><strong>Be a responsive host:</strong> Respond to guest inquiries promptly and professionally.</li>
              <li><strong>Be accurate in your listing:</strong> Make sure your listing photos and description are accurate and up-to-date.</li>
              <li><strong>Keep your property clean:</strong> Make sure your property is clean and well-maintained.</li>
              <li><strong>Make check-in easy:</strong> Provide clear check-in instructions and be available to answer questions.</li>
              <li><strong>Communicate effectively:</strong> Communicate important information to guests about their stay, such as house rules and parking instructions.</li>
            </ol>
            <Paragraph>By following these tips, you can improve your rental property rating and attract more guests to your listing.</Paragraph>
          </TipContent>
        </Panel>
        <Panel header="Reviews" key="2">
          <TipContent>
          </TipContent>
        </Panel>
        <Panel header="Min Stay" key="3">
          <TipContent>
          </TipContent>
        </Panel>
        <Panel header="Image Score" key="4">
          <TipContent>
          </TipContent>
        </Panel>
      </StyledCollapse>
    </Modal>
  );
};

export default OptimizationTipsModal;
