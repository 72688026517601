import React, { useCallback, useEffect, useState } from 'react';
import { message, Switch } from 'antd';
import styled from 'styled-components';
import { markets } from '../../../../services/markets';
import { useDispatch } from 'react-redux';
import { getListings } from '../../../../appRedux/actions';

const DynamicBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AntSwitch = styled(Switch)`
  background-color: ${({$background}) => $background};
`;


const DynamicPricing = ({value, rowIndex, colIndex, setPopupParams, popupParams}) => {

  const [switchChecked, isSwitchChecked] = useState(false);

  const {id, isVisible, updating} = popupParams;

  const dispatch = useDispatch();

  useEffect(() => {
    isSwitchChecked(value.isDynamicPricingEnabled);
  },[value.isDynamicPricingEnabled]);

  const onToggleSwitch = async(e) => {
    try {
      if (e) {
        const tableRow = document.querySelector(`#tablerow${rowIndex}`);
        const tableCol = document.querySelector(`#tablecol${colIndex}`);
        setPopupParams(({
          top: tableRow?.offsetTop - tableRow?.parentNode?.scrollTop,
          left: tableCol?.offsetLeft,
          isVisible: true,
          id: value?.id,
          updating: true,
        }));
        return;
      }

      const dynamicSetResult = await markets.updateListingPricing({id: value.id, params: {isDynamicPricingEnabled: false} });

      if (!dynamicSetResult.status){
        message.error(`Error on Silent Dynamic Pricing update. Check subscription`)
        isSwitchChecked(true);
        setPopupParams((prev) => ({...prev, updating: false}))
        return;
      }; 

      dispatch(getListings());


    } catch (e) {
      console.error(e);
    }

  }

  const switchBackground = useCallback(() => {
    if (updating && id === value?.id && !isVisible)  {
      return '#939899';
    } else if (switchChecked) {
      return '#1BB3CD';
    }  else if (id === value?.id && isVisible) {
      return '#D79E34';
    } 

    return 'rgba(0, 0, 0, 0.25)';

  }, [switchChecked, id, value, isVisible, updating]);

  return (
    <DynamicBody >
      <AntSwitch       
        disabled={(popupParams.updating && value?.id === id) || (!value?.isDynamicPricingReady)} 
        $background={switchBackground()} checked={switchChecked} onChange={onToggleSwitch} />
    </DynamicBody>
  )
}

export default DynamicPricing;